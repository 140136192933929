import { FC, useCallback } from 'react';

import classNames from 'classnames';
import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import { messages } from './messages';
import { modalApi, Modals$ } from '../../../effector/modals';
import { PAGE_PERSONAL } from '../../../Pages/Routes';
import { Button } from '../../Button/Button';
import { Icon } from '../../Icon';

type Props = {
  className?: string;
};

const Approval: FC<Props> = ({ className }) => {
  const modalsState = useStore(Modals$);
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate(PAGE_PERSONAL);
    modalApi.hide('');
  }, [navigate]);

  return (
    <div className={classNames('modal-attention', className)}>
      <div className="modal-attention__icon">
        <Icon className="modal-attention__svg" name="approval" width={136} height={109} />
      </div>
      {modalsState.data?.text && (
        <div
          className="modal-attention__text"
          dangerouslySetInnerHTML={{
            __html: modalsState.data.text,
          }}
        />
      )}
      <div className="modal-attention__action">
        <Button className="_primary _large" onClick={onButtonClick} text={messages.continue} />
      </div>
    </div>
  );
};

export default Approval;
