/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { useStore } from 'effector-react';

import { ConfirmStrategyAction } from '../api';
import { modalApi, Modals$ } from '../effector/modals';
import { GAService } from '../services/gaService';

const defaultSecondsToUnblock = 60;

type Params = {
  bindConfirm: (data: ConfirmStrategyAction) => Promise<any>;
  bindSendSms: (data: ConfirmStrategyAction) => Promise<any>;
  bindGetDocument: (data: ConfirmStrategyAction) => Promise<any>;
  isBinding: boolean;
  name: string;
  onSuccess: string;
  gaEventName: string;
};

type HOC = (WrappedComponent: React.FunctionComponent, params: Params) => React.FC<{ className?: string }>;

export const withSubscription: HOC = (WrappedComponent, params) => {
  type Props = {
    className?: string;
  };

  const Inner: React.FC<Props> = ({ className, ...rest }) => {
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [time, setTime] = useState<number>(0);
    const [kod, setKod] = useState<string>('');
    const [doc, setDoc] = useState<string>('');

    const modalState = useStore(Modals$);

    const getDocument = useCallback(async () => {
      try {
        const { errorMessage, success, response } = await params.bindGetDocument({
          bindingId: modalState.data?.bindingId,
          documentFormat: 'html',
        });

        if (success) {
          setDoc(response);
        } else {
          setError(errorMessage);
        }
      } catch (e) {}
    }, []);

    const reSendCode = useCallback(async () => {
      try {
        const { errorMessage, success } = await params.bindSendSms({
          bindingId: modalState.data?.bindingId,
        });
        if (success) {
          setTime(defaultSecondsToUnblock);
        } else {
          setError(errorMessage);
        }
      } catch (e) {}
    }, []);

    useEffect(() => {
      setError('');
    }, [kod]);

    useEffect(() => {
      getDocument();
      reSendCode();
    }, []);

    const handleSubmit = useCallback(async () => {
      setLoading(true);
      GAService.sendEvent(params.gaEventName);

      try {
        const { errorMessage, success } = await params.bindConfirm({
          bindingId: modalState.data?.bindingId,
          confirmationCode: kod,
        });
        const prevData = modalState.data;
        if (success) {
          modalApi.show({
            modalId: params.onSuccess,
            data: {
              text: prevData?.text,
            },
          });

          prevData?.action?.();
        } else {
          setError(errorMessage);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }, [kod]);

    return (
      <div className={cn('auto-following', className)}>
        <WrappedComponent
          {...rest}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errorMessage={error}
          onSubmit={handleSubmit}
          isBinding={params.isBinding}
          isDisabled={!kod || error}
          content={doc}
          time={time}
          reSendCode={reSendCode}
          onTimerFinish={() => {
            setTime(0);
          }}
          onKodChange={setKod}
          loading={loading}
        />
      </div>
    );
  };

  return Inner;
};
