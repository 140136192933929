import { FC } from 'react';

import classNames from 'classnames';

import { YAService } from '../../../services/yaService';
import { messages } from '../messages';

export const Contact: FC<{ className?: string }> = ({ className }) => (
  <div className={classNames('contact', className)}>
    <div className="contact__title">{messages.phoneDescription}</div>
    <a
      href="tel:88005005545"
      className="contact__phone"
      data-action={YAService.eventTreeToString({
        footer: 'phone',
      })}
    >
      {messages.phone}
    </a>
  </div>
);
