import React, { FC, useCallback } from 'react';

import classNames from 'classnames';

import { YAService } from '../../services/yaService';
import { Link } from '../Link';
import { LoadingDots } from '../UIKit/LoadingDots';

type Props = {
  text: string | JSX.Element;
  link?: string;
  tag?: 'a';
  type?: 'button' | 'submit';
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
};

export const Button: FC<Props> = ({
  tag,
  link,
  className,
  text,
  type,
  onClick,
  disabled = false,
  loading = false,
  ...rest
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const actionName = e.currentTarget.dataset.action;
      if (actionName) {
        YAService.sendEvent(actionName);
      }
      onClick?.();
    },
    [onClick],
  );

  if (tag === 'a') {
    return (
      <Link className={classNames('button', className)} link={link as string} onClick={handleClick} {...rest}>
        <span className="button__text">{text}</span>
      </Link>
    );
  }

  if (type === 'submit') {
    return (
      <button
        className={classNames('button', className)}
        disabled={disabled || loading}
        onClick={handleClick}
        type="submit"
        {...rest}
      >
        <LoadingDots className={classNames({ 'button-hide': !loading })} />
        <span className={classNames('button__text', { 'button-hide': loading })}>{text}</span>
      </button>
    );
  }

  return (
    <button
      className={classNames('button', className)}
      disabled={disabled || loading}
      onClick={handleClick}
      type="button"
      {...rest}
    >
      <LoadingDots className={classNames({ 'button-hide': !loading })} />
      <span className={classNames('button__text', { 'button-hide': loading })}>{text}</span>
    </button>
  );
};
