import React from 'react';

import classNames from 'classnames';

import { Tickers } from './Tickers';
import { YAService } from '../../services/yaService';
import { Logo } from '../Logo';
import { Navbar } from '../PageHeader/Navbar';

export const LARGE_CLASS = '_large';

type Props = {
  className: string;
};

const PageHeader: React.FC<Props> = ({ className }) => (
  <header
    className={classNames('page-header', className)}
    data-observable={YAService.eventTreeToString({
      header: 'view',
    })}
  >
    <div className="page-header__container">
      <div className="page-header__main">
        <Logo className="page-header__logo _light _large" place="header" />
        <Navbar />
      </div>
      <Tickers />
    </div>
  </header>
);

export default PageHeader;
