import React from 'react';

import classNames from 'classnames';

import { ReactComponent as LogoIcon } from './assets/logo.svg';
import { YAService } from '../../services/yaService';
import { Link } from '../Link';

type Props = {
  className: string;
  place: 'header' | 'footer';
};

export const Logo: React.FC<Props> = ({ place, className }) => (
  <div className={classNames('logo', className)}>
    <Link
      link="/"
      className="logo__link"
      data-action={YAService.eventTreeToString({
        [place]: 'logo',
      })}
    >
      <div className="logo__icon">
        <LogoIcon />
      </div>
    </Link>
  </div>
);
