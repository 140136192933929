import React from 'react';

import cn from 'classnames';
import { useStore } from 'effector-react';

import { Modals$ } from '../../../effector/modals';
import { Icon } from '../../Icon';

type Props = {
  className?: string;
};

const imageSize = {
  width: 136,
  height: 109,
};

export const Subscribe: React.FC<Props> = ({ className }) => {
  const modalsState = useStore(Modals$);

  return (
    <div className={cn('modal-attention', className)}>
      <div className="modal-attention__icon">
        <Icon className="modal-attention__svg" name="approval" width={imageSize.width} height={imageSize.height} />
      </div>
      {modalsState.data?.description && (
        <div className="modal-attention__text" dangerouslySetInnerHTML={{ __html: modalsState.data?.description }} />
      )}
    </div>
  );
};
