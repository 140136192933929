export const messages = {
  phoneDescription: 'Бесплатный звонок по россии',
  phone: '8 800 500 55 45',
  linkStrategies: 'Стратегии',
  linkAnalytics: 'Аналитика',
  linkAuthors: 'Авторам',
  linkProject: 'О проекте',
  linkFaq: 'FAQ',
  exit: 'Выйти',
  openAccount: 'Открыть счет',
  lk: 'Личный кабинет',
  enter: 'Войти',
  menu: 'Меню',
};
