import { LOGOUT } from '../const/event';
import { Config$ } from '../effector/configStore';
import { triggerCustomEvent } from '../effector/customMessage';
import { AuthenticationService } from '../services/authenticationService';

const FORBIDDEN = 403;
const UNAUTHORIZED = 401;
const NO_CONTENT = 204;

type Config = {
  basePath: string;
  agent: (input: RequestInfo, options: RequestInit) => Promise<Response>;
  errorCatcher: (err: Error) => void;
};

enum ApiType {
  Internal = 'Internal',
  Profile = 'Profile',
  Agreement = 'Agreement',
  Money = 'Money',
  Faq = 'Faq',
}

const API_WITH_EXCLUDED_HEADERS = [ApiType.Agreement, ApiType.Money, ApiType.Faq];

export enum RequestTypes {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type Options = {
  headers: Record<string, string>;
  method: RequestTypes;
  body?: string;
};

const config: Config = {
  basePath: '/api/',
  agent: fetch.bind(window),
  errorCatcher: (err: Error) => console.log('log error:', err),
};

const createEndpoint = (endpoint: string) => `${config.basePath}${endpoint}`;
const createProfileEndpoint = (endpoint: string) => {
  const conf = Config$.getState();
  return `${conf.profileApiUrl}${endpoint}`;
};

const createAgreementEndpoint = (endpoint: string) => {
  const conf = Config$.getState();
  return `${conf.agreementApiUrl}${endpoint}`;
};

const createMoneyEndpoint = (endpoint: string) => {
  const conf = Config$.getState();
  return `${conf.moneyApiUrl}${endpoint}`;
};

const createFaqEndpoint = (endpoint: string) => {
  const conf = Config$.getState();
  return `${conf.faqApiUrl}${endpoint}`;
};

const createOptions: (method: RequestTypes, body?: string, apiType?: ApiType) => Options = (method, body, apiType) => {
  const defaultOptions = {
    headers: {
      Authorization: AuthenticationService.headerAccessToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(!API_WITH_EXCLUDED_HEADERS.includes(apiType as ApiType) ? { 'X-App-Name': 'FTWft' } : {}),
    },
    method,
  };

  if (method === RequestTypes.GET) {
    return defaultOptions;
  }

  return {
    ...defaultOptions,
    body,
  };
};

const checkResponseOk = (response: Response) => {
  if (!response.ok) {
    throw new Error(
      JSON.stringify({
        type: 'api',
        url: response.url,
        status: response.status,
        statusText: response.statusText,
      }),
    );
  }

  return response;
};

const checkAppVersion = (response: Response) => {
  const headerKey = 'X-App-Version';
  const storageKey = 'appVersion';
  const appVersion = response.headers.get(headerKey);
  const currentAppVersion = localStorage.getItem(storageKey);

  if (currentAppVersion && appVersion !== currentAppVersion) {
    localStorage.setItem(storageKey, appVersion as string);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location.reload(true);
  } else {
    localStorage.setItem(storageKey, appVersion as string);
  }

  return response;
};

const checkResponseCode = (response: Response) => {
  switch (response.status) {
    case NO_CONTENT:
      return new Response('{}');

    default:
      return response;
  }
};

const createRequestByType =
  (type: RequestTypes, apiType = ApiType.Internal) =>
  (endpoint: string, body?: Record<string, unknown>) => {
    const options: Options = createOptions(type, JSON.stringify(body), apiType);

    return tryFetch(endpoint, options, apiType);
  };

const request = (url: string, options: Options, apiType: ApiType) => {
  switch (apiType) {
    case ApiType.Internal:
      return config
        .agent(createEndpoint(url), options)
        .then(checkResponseOk)
        .then(checkAppVersion)
        .then(checkResponseCode)
        .then((res) => res.json());
    case ApiType.Profile:
      return config
        .agent(createProfileEndpoint(url), options)
        .then(checkResponseOk)
        .then(checkResponseCode)
        .then(async (res) => {
          let response;
          try {
            response = await res.json();
          } catch (err) {
            try {
              const blob = await response.blob();
              const url = URL.createObjectURL(blob);
              response = url;
            } catch (errParse) {
              response = res;
            }
          }
          return { headers: res?.headers || new Headers(), response };
        });
    case ApiType.Agreement:
      return config
        .agent(createAgreementEndpoint(url), options)
        .then(checkResponseOk)
        .then(checkResponseCode)
        .then((res) => res.json());
    case ApiType.Money:
      return config
        .agent(createMoneyEndpoint(url), options)
        .then(checkResponseOk)
        .then(checkResponseCode)
        .then((res) => res.json());
    case ApiType.Faq:
      return config
        .agent(createFaqEndpoint(url), options)
        .then(checkResponseOk)
        .then(checkResponseCode)
        .then((res) => res.json());
    default:
      return config
        .agent(createEndpoint(url), options)
        .then(checkResponseOk)
        .then(checkAppVersion)
        .then(checkResponseCode)
        .then((res) => res.json());
  }
};

const tryFetch = async (url: string, options: Options, apiType: ApiType) => {
  try {
    return await request(url, options, apiType);
  } catch (err) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const status = JSON.parse(err.message).status;
      const inTokenError = status === FORBIDDEN || status === UNAUTHORIZED;

      if (!inTokenError) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw Error(err);
      }

      // Skip try, when token is empty
      if (!AuthenticationService.accessToken || !AuthenticationService.refreshToken) {
        return;
      }

      const result = await refreshToken(AuthenticationService.refreshToken);

      AuthenticationService.accessToken = result?.access_token || '';
      AuthenticationService.refreshToken = result?.refresh_token || '';

      return await request(
        url,
        {
          ...options,
          headers: {
            ...options.headers,
            Authorization: AuthenticationService.headerAccessToken,
          },
        },
        apiType,
      );
    } catch (e) {
      if (!AuthenticationService.accessToken || !AuthenticationService.refreshToken) {
        triggerCustomEvent(LOGOUT);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      config.errorCatcher(e);
      return {
        errorMessage: 'Произошла непредвиденная ошибка',
        success: false,
        response: null,
      };
    }
  }
};

export const get = createRequestByType(RequestTypes.GET);

export const post = createRequestByType(RequestTypes.POST);

export const put = createRequestByType(RequestTypes.PUT);

export const deleteRequest = createRequestByType(RequestTypes.DELETE);

const refreshToken = (token: string) => {
  const conf = Config$.getState();
  const options: Options = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: RequestTypes.POST,
    body:
      `grant_type=refresh_token&refresh_token=${token}&client_id=${conf.clientId}` +
      ((conf.clientSecret && `&client_secret=${conf.clientSecret}`) || ''),
  };

  return config
    .agent(conf.authorizationUrl, options)
    .then(checkResponseOk)
    .then(checkResponseCode)
    .then((res) => res.json())
    .catch(config.errorCatcher);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const auth = (data) => {
  const conf = Config$.getState();
  const options: Options = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: RequestTypes.POST,
    body: data,
  };

  // tslint:disable-next-line:max-line-length
  return config
    .agent(conf.authorizationUrl, options)
    .then(checkResponseOk)
    .then(checkResponseCode)
    .then((res) => res.json())
    .catch(config.errorCatcher);
};

export const profileApi = {
  get: createRequestByType(RequestTypes.GET, ApiType.Profile),
  post: createRequestByType(RequestTypes.POST, ApiType.Profile),
};

export const agreementApi = {
  get: createRequestByType(RequestTypes.GET, ApiType.Agreement),
};

export const moneyApi = {
  get: createRequestByType(RequestTypes.GET, ApiType.Money),
};

export const faqApi = {
  get: createRequestByType(RequestTypes.GET, ApiType.Faq),
};
