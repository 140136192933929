import { Suspense, lazy } from 'react';

import { Route, Routes as BaseRoutes } from 'react-router-dom';

import { ErrorBoundary } from '../components/ErrorBoundary';
import { GlobalLoader } from '../components/UIKit/GlobalLoader';

const MainPage = lazy(() => import('./MainPage'));
const StrategiesPage = lazy(() => import('./StrategiesPage'));
const Page404 = lazy(() => import('./Page404/Page404'));
const SupportFaq = lazy(() => import('./SupportFaq'));
const PersonalPage = lazy(() => import('./PersonalPage'));
const StrategyItem = lazy(() => import('./StrategyItem'));
const AboutPage = lazy(() => import('./AboutPage/AboutPage'));
const ExpertsPage = lazy(() => import('./Experts'));
const GetFreePage = lazy(() => import('./GetFree'));
const AuthorPage = lazy(() => import('./AuthorPage'));
const Articles = lazy(() => import('./Articles'));
const Article = lazy(() => import('./Article'));
const UnsubscribePage = lazy(() => import('./Unsubscribe'));
const StrategySecurities = lazy(() => import('./StrategySecurities'));
const Services = lazy(() => import('./Services'));

export const PAGE_HOME = '/';
export const PAGE_SUPPORT_FAQ = '/faq';
export const PAGE_SUPPORT_FAQ_CATEGORY = ':category';
export const PAGE_SUPPORT_FAQ_QUESTION = ':question';
export const PAGE_ABOUT = '/about';
export const PAGE_STRATEGIES = '/strategies';
export const PAGE_STRATEGY_ITEM = '/strategies/:id';
export const PAGE_PERSONAL = '/personal';
export const PAGE_GET_FREE = '/getitfree';
export const PAGE_EXPERTS = '/experts';
export const PAGE_AUTHOR = '/experts/:id';
export const PAGE_AUTHOR_STRATEGIES = 'strategies';
export const PAGE_AUTHOR_ARTICLES = 'blog';
export const PAGE_ARTICLES = '/blogs';
export const PAGE_ARTICLE = '/blogs/:id';
export const PAGE_UNSUBSCRIBE = '/unsubscribe';
export const PAGE_STRATEGY_SECURITY = '/strategies/security';
export const PAGE_STRATEGY_SECURITIES = '/strategies/security/:id';
export const PAGE_SERVICES = '/services/';

export const PAGE_TARIFFS = 'https://broker.ru/regulations';
export const PAGE_ONBOARDING = 'https://my.broker.ru/dobs/partner-base?guid=2113BA44-71D6-44B6-8590-813DA353CF90';

const Routes = () => (
  <ErrorBoundary>
    <Suspense fallback={<GlobalLoader />}>
      <BaseRoutes>
        <Route path={PAGE_HOME} element={<MainPage />} />
        <Route path={PAGE_STRATEGIES} element={<StrategiesPage />} />
        <Route path={PAGE_STRATEGY_ITEM} element={<StrategyItem />} />
        <Route path={PAGE_PERSONAL} element={<PersonalPage />} />
        <Route path={`${PAGE_SUPPORT_FAQ}/*`} element={<SupportFaq />} />
        <Route path={PAGE_ABOUT} element={<AboutPage />} />
        <Route path={PAGE_GET_FREE} element={<GetFreePage />} />
        <Route path={PAGE_EXPERTS} element={<ExpertsPage />} />
        <Route path={`${PAGE_AUTHOR}/*`} element={<AuthorPage />} />
        <Route path={PAGE_ARTICLES} element={<Articles />} />
        <Route path={PAGE_ARTICLE} element={<Article />} />
        <Route path={PAGE_UNSUBSCRIBE} element={<UnsubscribePage />} />
        <Route path={PAGE_STRATEGY_SECURITIES} element={<StrategySecurities />} />
        <Route path={PAGE_SERVICES} element={<Services />} />
        <Route path="*" element={<Page404 />} />
      </BaseRoutes>
    </Suspense>
  </ErrorBoundary>
);

export default Routes;
