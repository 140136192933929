import React, { useState } from 'react';

import cn from 'classnames';

export const ContentBlock: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => {
  const [isOpened, setOpen] = useState(false);

  const onButtonClick = () => {
    setOpen(!isOpened);
  };

  return (
    <div className={cn('content-block', className)}>
      <div
        className={cn('content-block__inner', {
          'content-block__inner-opened': isOpened,
        })}
      >
        {children}
      </div>
      <button className="content-block__toggle" type="button" onClick={onButtonClick}>
        {isOpened ? 'Свернуть' : 'Развернуть'}
      </button>
    </div>
  );
};
