export const products = [
  {
    label: 'БКС Мир Инвестиций',
    link: 'https://bcs.ru',
  },
  {
    label: 'БКС Экспресс',
    link: 'https://bcs-express.ru',
  },
  {
    label: 'БКС Банк',
    link: 'https://bank.bcs.ru',
  },
  {
    label: 'БКС Форекс',
    link: 'https://bcs-forex.ru',
  },
  {
    label: 'БКС Страхование жизни',
    link: 'https://bcslife.ru',
  },
  {
    label: 'БКС Карьера',
    link: 'https://bcs.career',
  },
];
