/* eslint-disable @typescript-eslint/no-explicit-any */
import { noun } from 'plural-ru';
import queryString from 'query-string';

import { Agreement } from '../types/agreement';
import { CurrencyId } from '../types/money';

/**
 * получаем ширину системного скролла
 * @return {number} Ширина скролла.
 */
export const scrollWidth = () => {
  // создадим элемент с прокруткой
  const div = document.createElement('div');

  div.style.overflowY = 'scroll';
  div.style.width = '50px';
  div.style.height = '50px';

  // при display:none размеры нельзя узнать
  // нужно, чтобы элемент был видим,
  // visibility:hidden - можно, т.к. сохраняет геометрию
  div.style.visibility = 'hidden';

  document.body.appendChild(div);
  const scrollWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  return scrollWidth;
};

export const isBrowser = typeof window !== 'undefined';

export const floorPercent = (value: number) => {
  const absValue = Math.abs(value);

  return absValue > 100 ? Math.floor(absValue) : absValue;
};

export const getRightCurrencyId = (currency: string): CurrencyId => {
  switch (currency) {
    case 'SUR':
    case 'RUB':
    case 'RUR':
      return CurrencyId.RUB;
    default:
      return currency as CurrencyId;
  }
};

export const getCurrencySymbol = (currency: string) => {
  const currencies: Record<string, string> = {
    RUB: '₽',
    SUR: '₽',
    USD: '$',
    EUR: '€',
    HKD: 'HK$',
    '%': '%',
    undefined: '',
    null: '',
  };

  return currencies[currency];
};

export const formatNumber = (value: number, toFixed: number) => {
  const formatter = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: toFixed,
    maximumFractionDigits: toFixed,
  });

  return formatter.format(value);
};

export const formatMoney = (value: number, currency = 'RUB', toFixed = 2) => {
  const formattedValue = formatNumber(value, toFixed);

  if (!value) return '';

  const symbol = getCurrencySymbol(currency);

  return `${formattedValue} ${symbol || currency}`;
};

export const formatNumeralMoney = (value: string, currency = 'RUB') => {
  const symbol = getCurrencySymbol(currency);

  return `${value} ${symbol || currency}`;
};

export const roundNumber = (value: number, to = 2) => parseFloat(value.toFixed(to));

export const getProgressSymbol = (value: number) => {
  let symbol = '';

  if (value > 0) {
    symbol = '+';
  } else if (value < 0) {
    symbol = '-';
  }

  return symbol;
};

export const getProfitDescription = (profitValue: number, profitDuration: number, unit: string) => {
  let pluralUnit = '';

  switch (unit) {
    case 'month':
    case 'months':
      pluralUnit = noun(profitDuration, '%d месяц', '%d месяца', '%d месяцев');
      break;
    case 'year':
    case 'years':
      pluralUnit = noun(profitDuration, '%d год', '%d года', '%d лет');
      break;
    default:
      pluralUnit = noun(profitDuration, '%d год', '%d года', '%d лет');
  }

  return `${getProgressSymbol(profitValue)} ${formatNumber(Math.abs(profitValue), 0)}% за ${pluralUnit}`;
};

export const getNestedProperty = (object: any, path: string) => {
  return path.split('.').reduce((m, i) => {
    return m && typeof m === 'object' ? m[i] : undefined;
  }, object);
};

export const withQuery =
  (...args: any[]) =>
  (endpoint: string) => {
    const commonQueryObj: Record<string, string> = {};

    args.forEach((queryObj) => {
      Object.keys(queryObj).forEach((queryObjKey) => {
        if (queryObj[queryObjKey]) {
          commonQueryObj[queryObjKey] = queryObj[queryObjKey];
        }
      });
    });

    const result = queryString.stringifyUrl({
      url: endpoint,
      query: commonQueryObj,
    });

    return result;
  };

const STORAGE_TYPE = 'Накопилка';

export const hasStorageTypeInAgreement = (agreement: Agreement) =>
  agreement.extendedServices?.some((service) => service.type === STORAGE_TYPE);
