/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { messages } from './messages';
import { bindInitiate } from '../../../api';
import { POP_UP } from '../../../const';
import { Account$, accountApi } from '../../../effector/clientAccounts';
import { modalApi } from '../../../effector/modals';
import { Strategy$ } from '../../../effector/strategy';
import { useAgreementList } from '../../../hooks/queries/useAgreementList';
import { useMoney } from '../../../hooks/queries/useMoney';
import { Agreement } from '../../../types/agreement';
import { AgreementMoney, CurrencyId } from '../../../types/money';
import { Option } from '../../../types/option';
import { ServiceKind } from '../../../types/strategy';
import { Nullable } from '../../../types/utils';
import { formatMoney, getRightCurrencyId, hasStorageTypeInAgreement, roundNumber } from '../../../utils';
import { required } from '../../../utils/Form/formik-validation';
import { Button } from '../../Button/Button';
import { Select } from '../../FormComponents/Select';

export const BINDING_TYPE = {
  AUTO_FOLLOWING: 1,
  RECOMMENDATIONS: 2,
};

const speedSettings = {
  invest: {
    label: messages.minInvest,
    addition: '',
  },
  drawdown: {
    label: messages.drawdown,
    addition: '%',
  },
};

const validationSchema = Yup.object().shape({
  bill: Yup.object().nullable().required(required),
});

const initialValues = {
  bill: null,
};

type FormFields = {
  bill: Nullable<Option>;
};

type FormProps = {
  account?: Agreement;
  agreementMoney?: AgreementMoney;
  options: Option[];
  error: string;
  currency: CurrencyId;
  checkBillRestriction: (bill: Nullable<Option>) => void;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({
  account,
  agreementMoney,
  currency,
  options,
  values,
  error,
  checkBillRestriction,
}) => {
  useEffect(() => {
    checkBillRestriction(values.bill);
  }, [values, checkBillRestriction]);

  const marketValue = agreementMoney?.marketValue?.[currency]?.marketValue;
  const freeValue = agreementMoney?.freeMoney?.[currency]?.freeMoney;

  return (
    <Form noValidate>
      <div className="modal-strategy__info-row _account">
        <div className="modal-strategy__info-title">{messages.account}</div>
        <div className="modal-strategy__info-description">
          <Field
            name="bill"
            component={Select}
            className="modal-strategy__select _small"
            placeholder="Выбрать счет для подключения"
            options={options}
          />

          <div className="modal-strategy__account-content">
            {account && marketValue && currency && (
              <div className="modal-strategy__account-row">
                <div className="modal-strategy__account-title">{messages.funds}</div>
                <div className="modal-strategy__account-info">{formatMoney(marketValue, currency, 0)}</div>
              </div>
            )}
            {account && freeValue && currency && (
              <div className="modal-strategy__account-row">
                <div className="modal-strategy__account-title">{messages.freeFunds}</div>
                <div className="modal-strategy__account-info">{formatMoney(freeValue, currency, 0)}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {error && <p className="error-message" dangerouslySetInnerHTML={{ __html: error }} />}
    </Form>
  );
};

type Props = {
  className?: string;
};

const AddStrategy: React.FC<Props> = ({ className }) => {
  const [errorMessage, setError] = useState<string>('');
  const [bill, setBill] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [accountHasPositions, setAccountHasPositions] = useState<boolean>(false);

  const { refreshedAccounts } = useStore(Account$);
  const strategy = useStore(Strategy$);
  const { data: money } = useMoney();
  const { data: accountData } = useAgreementList();

  useEffect(() => {
    if (strategy) {
      accountApi.refreshAccountsV2(strategy.id);
    }
    return () => {
      accountApi.resetRefreshV2('');
    };
  }, [strategy]);

  const getPromotion = useCallback(
    (type: ServiceKind) => {
      const selectedAccount = accountData?.agreements?.find((acc) => acc.id === bill);

      return (
        selectedAccount &&
        refreshedAccounts
          .find(({ agreementId }) => agreementId === selectedAccount?.id)
          ?.campaigns?.find(({ serviceKind }: { serviceKind: ServiceKind }) => serviceKind === type)
      );
    },
    [accountData, refreshedAccounts, bill],
  );

  const afPromotion = useMemo(() => getPromotion(ServiceKind.AF), [getPromotion]);
  const acPromotion = useMemo(() => getPromotion(ServiceKind.AC), [getPromotion]);

  const checkBillRestriction = (newBill: Nullable<Option>) => {
    if (newBill && newBill.id !== bill) {
      const agreement = accountData?.agreements?.find((acc) => acc.id === newBill.id);
      const agreementMoney = money?.find((item) => item.id === agreement?.id);

      if (agreementMoney && strategy) {
        const currency = getRightCurrencyId(strategy.currency.id);
        const marketValue = agreementMoney.marketValue?.[currency]?.marketValue;
        const freeValue = agreementMoney.freeMoney?.[currency]?.freeMoney;

        setAccountHasPositions(marketValue !== freeValue);
      }

      setBill(newBill?.id);
      setError('');
      setAccountHasPositions(true);
    }
  };

  // @ts-ignore
  const sendData = async (connectionParams, type) => {
    const account = accountData?.agreements?.find((acc) => acc.id === bill);
    const binding = {
      ...connectionParams,
      strategyId: strategy?.id,
      agreementId: account?.id,
    };

    setLoading(true);

    if (accountHasPositions && binding.serviceKind === BINDING_TYPE.AUTO_FOLLOWING) {
      modalApi.show({
        modalId: POP_UP.AUTO_FOLLOWING_ACCEPT,
        data: binding,
      });
    } else {
      try {
        const { errorMessage, success, response } = await bindInitiate(binding);

        if (success) {
          const popupData = {
            bindingId: response,
            text: binding.text,
          };
          modalApi.show({
            modalId: type,
            data: popupData,
          });
        }
        setError(errorMessage);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const openAutoFollowing = () => {
    const data = {
      serviceKind: BINDING_TYPE.AUTO_FOLLOWING,
      text: `<p>Спасибо! <br />
       Ваша заявка принята! <br />
       Статус подключения услуги вы можете увидеть в личном кабинете. <br /><br />
       После подключения автоследования вы получите на электронную почту индивидуальную инвестиционную рекомендацию, на основании которой будет произведена балансировка портфеля.
       </p>
        `,
    };

    sendData(data, POP_UP.AUTO_FOLLOWING);
  };

  const openRecommendations = () => {
    const data = {
      serviceKind: BINDING_TYPE.RECOMMENDATIONS,
      text: `<p>Спасибо! <br />
       Ваша заявка принята! <br />
       Статус подключения услуги вы можете увидеть в личном кабинете.<br /><br />
       После подключения автоконсультирования вы получите на электронную почту индивидуальную инвестиционную рекомендацию, на основании которой вы можете привести свой инвестиционный портфель в соответствие портфелю выбранной стратегии.
       </p>
        `,
    };

    sendData(data, POP_UP.RECOMMENDATIONS);
  };

  const getGrangeData = () => {
    const settings = [
      {
        ...speedSettings.drawdown,
        value: roundNumber(strategy?.estimatedRisk ?? 0),
      },
      {
        ...speedSettings.invest,
        value: formatMoney(strategy?.subscriptionThreshold ?? 0, strategy?.currency.id, 0),
      },
    ];

    return settings;
  };

  const getAccountsData = () => {
    const options = (accountData?.agreements || [])
      .filter((item) => item.accepted || !item.closeDate || !hasStorageTypeInAgreement(item))
      .map((item) => ({
        name: item.displayName || item.number?.toString(),
        id: item.id,
      }));

    return options || [];
  };

  const isCorrect = () => {
    return !bill || !!errorMessage;
  };

  if (!strategy || !accountData?.agreements?.length || !money?.length) {
    return null;
  }

  const options = getAccountsData();
  const granges = getGrangeData();
  const account = accountData?.agreements?.find((acc) => acc.id === bill);
  const agreementMoney = money.find((item) => item.id === bill);
  const currency = getRightCurrencyId(strategy.currency.id);

  return (
    <div className={cn('add-strategy', className)}>
      <div className="modal-strategy__info">
        <div className="modal-strategy__info-row">
          <div className="modal-strategy__info-title">{messages.strategy}</div>
          <div className="modal-strategy__info-description">{strategy.name}</div>
        </div>
        <div className="modal-strategy__info-row">
          <div className="modal-strategy__info-title">{messages.category}</div>
          <div className="modal-strategy__info-description">{strategy.priceCategory.id}</div>
        </div>
        <div className="modal-strategy__info-row">
          <div className="modal-strategy__info-title">{messages.investProfile}</div>
          <div className="modal-strategy__info-description">{strategy.minInvestProfile.name}</div>
        </div>
        <div className="modal-strategy__info-row">
          <div className="modal-strategy__info-title">{messages.strategyParams}</div>
          <div className="modal-strategy__info-description">
            {granges.map((item, key) => (
              <div key={key}>
                {item.label}: {item.value}
                {item.addition}
              </div>
            ))}
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          onSubmit={() => console.log('')}
        >
          {(props) =>
            // @ts-ignore
            React.createElement(FormTemplate, {
              ...props,
              account,
              options,
              agreementMoney,
              currency,
              error: errorMessage,
              checkBillRestriction,
            })
          }
        </Formik>
      </div>
      <div className="modal-strategy__buttons">
        <div className="modal-strategy__buttons-row">
          {strategy?.autoconsult && (
            <div className="modal-strategy__buttons-col">
              <div>
                <Button
                  disabled={isCorrect()}
                  loading={loading}
                  className="_primary _large _wide modal-strategy__button"
                  text={messages.connectAutofollowing}
                  onClick={openRecommendations}
                />
              </div>
              <div className="modal-strategy__buttons-description">{messages.recommendation}</div>
              <div className="modal-strategy__buttons-price">
                {messages.price}{' '}
                {acPromotion ? (
                  <span className="modal-strategy__buttons-promotion">{acPromotion.tariffDisplayValue}</span>
                ) : (
                  strategy.priceAC
                )}
              </div>
            </div>
          )}
          {strategy?.autofollow && (
            <div className="modal-strategy__buttons-col">
              <div>
                <Button
                  disabled={isCorrect()}
                  loading={loading}
                  className="_success _large _wide modal-strategy__button"
                  text={messages.connectAutoconsalting}
                  onClick={openAutoFollowing}
                />
              </div>
              <div className="modal-strategy__buttons-description">{messages.autofollowing}</div>
              <div className="modal-strategy__buttons-price">
                {messages.price}{' '}
                {afPromotion ? (
                  <span className="modal-strategy__buttons-promotion">{afPromotion.tariffDisplayValue}</span>
                ) : (
                  strategy.priceAF
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddStrategy;
