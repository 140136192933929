import React from 'react';

type Props = {
  name: string;
  width: number;
  height: number;
  className?: string;
};

export const Icon: React.FC<Props> = ({ className, width, height, name }) => (
  <svg className={className} width={width} height={height}>
    <use href={`#icon-${name}`} />
  </svg>
);
