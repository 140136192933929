import { createApi, createEffect, forward } from 'effector';

import { Config$ } from './configStore';
import { getConfig } from '../api';

const { set } = createApi(Config$, {
  set: (state, { result }) => {
    return result;
  },
});

export const configApi = {
  get: createEffect().use(getConfig),
};

forward({
  from: configApi.get.done,
  to: set,
});
