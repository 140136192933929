/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect } from 'react';

import cn from 'classnames';
import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { messages } from './messages';
import {
  bindConfirm,
  bindGetDocument,
  bindSendSms,
  unBindConfirm,
  unBindGetDocument,
  unBindSendSms,
} from '../../../api';
import { POP_UP } from '../../../const';
import { Modals$ } from '../../../effector/modals';
import { withSubscription } from '../../../HOC/withSubscription';
import { required } from '../../../utils/Form/formik-validation';
import { Button } from '../../Button/Button';
import { ContentBlock } from '../../ContentBlock';
import { Checkbox } from '../../FormComponents/Checkbox';
import { Input } from '../../FormComponents/Input';
import { Timer } from '../../Timer';

const validationSchema = Yup.object().shape({
  kod: Yup.string().required(required),
});

const initialValues = {
  kod: '',
  isAccepted: false,
};

type FormFields = {
  kod: string;
  isAccepted: boolean;
};

type FormProps = {
  error: string;
  time: number;
  loading: boolean;
  isDisabled: boolean;
  isBinding: boolean;
  reSendCode: () => void;
  onTimerFinish: () => void;
  onKodChange: (kod: string) => void;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({
  values,
  time,
  loading,
  isBinding,
  isDisabled,
  error,
  reSendCode,
  onTimerFinish,
  onKodChange,
}) => {
  const { pathname } = useLocation();
  const modalState = useStore(Modals$);

  useEffect(() => {
    onKodChange(values.kod);
  }, [values, onKodChange]);

  const handleInformationClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      localStorage.setItem('bindingId', modalState.data?.bindingId);
      window.open(`${window.location.origin}/services`, 'blank');
    },
    [pathname, modalState],
  );

  return (
    <Form noValidate>
      <Field placeholder="Код" name="kod" type="text" component={Input} className="_light _small" />

      {time > 0 ? (
        <div className="form__info">
          {messages.requestCodeAgain} <Timer time={time} timeFinishCallback={onTimerFinish} />
        </div>
      ) : (
        <div className="form__info form__info-available" onClick={reSendCode}>
          {messages.requestCode}
        </div>
      )}

      {isBinding && (
        <div>
          <Field
            name="isAccepted"
            text={
              <div>
                {messages.informed}{' '}
                <span className="link" onClick={handleInformationClick}>
                  {messages.information}
                </span>
              </div>
            }
            component={Checkbox}
          />
        </div>
      )}

      <Button
        type="submit"
        loading={loading}
        disabled={isDisabled || (isBinding && !values.isAccepted)}
        className="_success _large _wide form__button"
        text={messages.accept}
      />

      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

type Props = {
  time: number;
  className: string;
  content: string;
  errorMessage: string;
  loading: boolean;
  isDisabled: boolean;
  isBinding: boolean;
  onHandleChange: () => void;
  onSubmit: () => void;
  reSendCode: () => void;
  onTimerFinish: () => void;
  onKodChange: (kod: string) => void;
};

const AutoFollowingFabric: React.FC<Props> = ({
  className,
  content,
  time,
  errorMessage,
  isBinding,
  isDisabled,
  loading,
  onSubmit,
  reSendCode,
  onTimerFinish,
  onKodChange,
}) => {
  useEffect(() => {
    localStorage.removeItem('bindingId');
  }, []);

  return (
    <div className={cn('auto-following', className)}>
      <div className="modal__text-wrap">
        <div className="modal__text-block scrollbar">
          <ContentBlock className="_autofollowing">
            <div className="modal__text-title" />
            <p dangerouslySetInnerHTML={{ __html: content }} />
          </ContentBlock>
        </div>
      </div>
      <div className="modal__label">{messages.enterCode}</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(props) =>
          React.createElement(FormTemplate, {
            ...props,
            time,
            loading,
            isBinding,
            isDisabled,
            reSendCode,
            onTimerFinish,
            onKodChange,
            error: errorMessage,
          })
        }
      </Formik>
    </div>
  );
};

// @ts-ignore
export const AutoFollowing = withSubscription(AutoFollowingFabric, {
  isBinding: true,
  bindConfirm: bindConfirm,
  bindSendSms: bindSendSms,
  bindGetDocument: bindGetDocument,
  name: POP_UP.AUTO_FOLLOWING,
  onSuccess: POP_UP.APPROVAL,
  gaEventName: 'PersonalNewBind',
});

export const RemoveStrategyConfirmation = withSubscription(
  // @ts-ignore
  AutoFollowingFabric,
  {
    isBinding: false,
    bindConfirm: unBindConfirm,
    bindSendSms: unBindSendSms,
    bindGetDocument: unBindGetDocument,
    name: POP_UP.REMOVE_AUTO_FOLLOWING,
    onSuccess: POP_UP.APPROVAL,
    gaEventName: 'PersonalFailBind',
  },
);
