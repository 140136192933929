import { FC, useCallback, useRef, useState } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { products } from '../../../const/products';
import { useOutSideClick } from '../../../hooks/useOutsideClick';
import { Link } from '../../Link';
import { ReactComponent as ArrowLinkIcon } from '../arrow-link.svg';
import { ReactComponent as ArrowIcon } from '../arrow.svg';

type Props = {
  items: {
    link: string;
    text: string;
    action?: string;
  }[];
  className: string;
};

export const HeaderNav: FC<Props> = ({ className, items }) => {
  const reference = useRef(null);
  const { pathname } = useLocation();
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const onClick = useCallback(() => {
    return isOpened ? setIsOpened(false) : null;
  }, [isOpened]);

  useOutSideClick(reference, onClick);

  return (
    <nav className={classNames('main-nav', className)}>
      <ul className="main-nav__list">
        {items.map(({ link, text, action }, key) => (
          <li
            key={key}
            className={classNames('main-nav__item', {
              _active: pathname === link,
            })}
          >
            <Link link={link} className="main-nav__link" data-action={action} title={text}>
              {text}
            </Link>
          </li>
        ))}
        <span ref={reference} className="main-nav__item-dropdown-wrapper">
          <li
            className={classNames('main-nav__item main-nav__link main-nav__item-dropdown')}
            title="Проекты БКС"
            onClick={() => setIsOpened(!isOpened)}
          >
            Проекты БКС
            <ArrowIcon
              className={classNames('main-nav__item-dropdown-icon main-nav__item-dropdown-icon-inner', {
                'main-nav__item-dropdown-icon-open': isOpened,
              })}
            />
            <div
              className={classNames('main-nav__item-dropdown-menu', {
                'main-nav__item-dropdown-menu-visible': isOpened,
              })}
            >
              {products.map(({ label, link }) => (
                <Link
                  key={label}
                  link={link}
                  className="main-nav__item-dropdown-menu-item"
                  onClick={() => setIsOpened(false)}
                >
                  {label}
                  <ArrowLinkIcon className="main-nav__item-dropdown-menu-item-icon" />
                </Link>
              ))}
            </div>
          </li>
          <ArrowIcon
            onClick={() => setIsOpened(!isOpened)}
            className={classNames('main-nav__item-dropdown-icon  main-nav__item-dropdown-icon-outer', {
              'main-nav__item-dropdown-icon-open': isOpened,
            })}
          />
        </span>
      </ul>
    </nav>
  );
};
