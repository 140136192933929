/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, createApi, createEffect, forward } from 'effector';

export type TModal$State<T> = {
  auth: boolean;
  addStrategy: boolean;
  recommendations: boolean;
  applicationForManagers: boolean;
  removeStrategy: boolean;
  autoFollowing: boolean;
  autoFollowingAccept: boolean;
  approval: boolean;
  attention: boolean;
  removeAutoFollowing: boolean;
  nav: boolean;
  subscribe: boolean;
  data?: T;
};

const initialState: TModal$State<any> = {
  auth: false,
  addStrategy: false,
  recommendations: false,
  applicationForManagers: false,
  removeStrategy: false,
  autoFollowing: false,
  autoFollowingAccept: false,
  approval: false,
  attention: false,
  removeAutoFollowing: false,
  nav: false,
  subscribe: false,
};

export const Modals$ = createStore<TModal$State<any>>(initialState);

const { show, hide } = createApi(Modals$, {
  show: (state, { modalId, data }) => {
    const newState = { ...initialState };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newState[modalId] = true;
    newState.data = data;

    return newState;
  },
  hide: () => initialState,
});

export const modalApi = {
  show: createEffect(),
  hide: createEffect(),
};

forward({
  from: modalApi.show,
  to: show,
});

forward({
  from: modalApi.hide,
  to: hide,
});
