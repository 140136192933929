import { FC, useCallback, useState } from 'react';

import { useStore } from 'effector-react';

import { messages } from './messages';
import { unBindInitiate } from '../../../api';
import { POP_UP } from '../../../const';
import { modalApi, Modals$ } from '../../../effector/modals';
import { Button } from '../../Button/Button';

const RemoveStrategy: FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const modalsState = useStore(Modals$);

  const openAcceptTel = useCallback(async () => {
    try {
      const { errorMessage, success, response } = await unBindInitiate({
        strategyId: modalsState.data?.strategyId,
        agreementId: modalsState.data?.agreementId,
      });
      if (success) {
        const data = {
          bindingId: response,
          text: modalsState.data?.text,
          action: modalsState.data?.action,
        };
        modalApi.show({
          modalId: POP_UP.REMOVE_AUTO_FOLLOWING,
          data: data,
        });
      } else {
        setErrorMessage(errorMessage);
      }
    } catch (e) {}
  }, [modalsState]);

  return (
    <div>
      <p className="modal__text-content">{messages.title}</p>
      <p className="modal__text-content-warning" dangerouslySetInnerHTML={{ __html: modalsState.data?.unbindNote }} />
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <Button className="_primary _large _wide" text={messages.unbind} onClick={openAcceptTel} />
    </div>
  );
};

export default RemoveStrategy;
