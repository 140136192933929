export const messages = {
  minInvest: 'Минимальная сумма инвестиций',
  drawdown: 'Допустимая просадка',
  account: 'Счет',
  funds: 'Всего средств',
  freeFunds: 'Свободные средства',
  strategy: 'Стратегия',
  category: 'Категория стратегии',
  investProfile: 'Инвестиционный профиль',
  strategyParams: 'Параметры стратегии',
  recommendation:
    'Получайте персональные рекомендации по сделкам автора стратегии. Сделки по рекомендациям вы можете совершать самостоятельно в любое время',
  price: 'Стоимость услуги:',
  connectAutofollowing: 'Подключить автоконсультирование',
  connectAutoconsalting: 'Подключить автоследование',
  autofollowing:
    'Мы автоматически составим вам сбалансированный портфель по стратегии автора и будем следить за его актуальностью',
};
