import React from 'react';

import { useQuery } from 'react-query';
import Ticker from 'react-ticker';

import { Ticker as TickerComponent } from './Ticker';
import { getStrategiesProfits } from '../../../api';

export const Tickers: React.FC = () => {
  const { data } = useQuery('profits', getStrategiesProfits);

  const tickers = data?.response?.profits || [];
  const period = data?.response?.period;

  return (
    <div className="ticker-wrapper">
      <div className="ticker-wrapper__period">{period}:</div>
      <div className="ticker-wrapper__values">
        {tickers?.length > 0 ? (
          <Ticker>
            {() => (
              <div className="tickers page-header__tickers">
                {tickers?.map((ticker) => (
                  <TickerComponent key={ticker.strategyId} {...ticker} />
                ))}
              </div>
            )}
          </Ticker>
        ) : null}
      </div>
    </div>
  );
};
