/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useState } from 'react';

import queryString from 'query-string';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { ReactComponent as SpriteIco } from './assets/sprite-ico.svg';
import { PageFooter } from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import PopupManager from '../components/PopupManager/PopupManager';
import { GlobalLoader } from '../components/UIKit/GlobalLoader';
import { SEO_CONTENT } from '../const';
import { accountApi } from '../effector/clientAccounts';
import { configApi } from '../effector/config';
import { modalApi } from '../effector/modals';
import { useProfile } from '../hooks/queries';
import Routes from '../Pages/Routes';
import { QueryService, UTM_PARAMS } from '../services/queryService';
import { YAService } from '../services/yaService';
import { scrollWidth } from '../utils';

export const App: React.FC = () => {
  const [configIsLoaded, setConfigIsLoaded] = useState(false);
  const location = useLocation();
  const { refetch: fetchProfile } = useProfile(false);

  useEffect(() => {
    modalApi.hide('');
  }, [location]);

  const checkReferenceId = useCallback(() => {
    const searchString = location.search;
    const params = queryString.parse(searchString);

    if (params[UTM_PARAMS.REF_ID]) {
      // @ts-ignore
      QueryService.refId = params[UTM_PARAMS.REF_ID];
    }

    if (params[UTM_PARAMS.UTM_SOURCE]) {
      // @ts-ignore
      QueryService.utmSource = params[UTM_PARAMS.UTM_SOURCE];
    }

    if (params[UTM_PARAMS.UTM_CAMPAIGN]) {
      // @ts-ignore
      QueryService.utmCampaign = params[UTM_PARAMS.UTM_CAMPAIGN];
    }

    if (params[UTM_PARAMS.UTM_CONTENT]) {
      // @ts-ignore
      QueryService.utmContent = params[UTM_PARAMS.UTM_CONTENT];
    }

    if (params[UTM_PARAMS.UTM_MEDIUM]) {
      // @ts-ignore
      QueryService.utmMedium = params[UTM_PARAMS.UTM_MEDIUM];
    }

    if (params[UTM_PARAMS.UTM_TERM]) {
      // @ts-ignore
      QueryService.utmTerm = params[UTM_PARAMS.UTM_TERM];
    }
  }, [location]);

  useEffect(() => {
    if (!configIsLoaded) {
      configApi.get('').then(() => {
        setConfigIsLoaded(true);
      });
    }
  }, [configIsLoaded]);

  useEffect(() => {
    if (configIsLoaded) {
      accountApi.getAccount('').then(() => {
        fetchProfile();
      });
    }
  }, [configIsLoaded, fetchProfile]);

  useEffect(() => {
    scrollWidth();
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollWidth()}px`);

    checkReferenceId();
    YAService.initIntersection();
  }, [checkReferenceId]);

  const seo = SEO_CONTENT['/'];
  return (
    <>
      <Helmet {...seo} />
      <div className="invisible-container">
        <SpriteIco />
      </div>
      <PageHeader className={'page__header'} />
      <main className="page__content">{configIsLoaded ? <Routes /> : <GlobalLoader />}</main>
      <PageFooter className="page__footer" />
      <PopupManager />
    </>
  );
};
