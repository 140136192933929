import { FC } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { Link } from '../../Link';

type Props = {
  items: {
    link: string;
    text: string;
    action?: string;
  }[];
};

export const FooterNav: FC<Props> = ({ items }) => {
  const { pathname } = useLocation();
  return (
    <nav className={classNames('footer-nav')}>
      <ul className="footer-nav__list">
        {items.map(({ link, text, action }, key) => (
          <li
            key={key}
            className={classNames('footer-nav__item', {
              _active: pathname === link,
            })}
          >
            <Link link={link} className="footer-nav__link" data-action={action} title={text}>
              {text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
