/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { ReactComponent as BrokerIcon } from './bcs-broker.svg';
import { messages } from './messages';
import { auth } from '../../../api/apiHelper';
import { accountApi } from '../../../effector/clientAccounts';
import { Config$ } from '../../../effector/configStore';
import { modalApi, Modals$ } from '../../../effector/modals';
import { useProfile } from '../../../hooks/queries';
import { PAGE_ONBOARDING } from '../../../Pages/Routes';
import { AuthenticationService } from '../../../services/authenticationService';
import { GAService } from '../../../services/gaService';
import { QueryService, UTM_PARAMS } from '../../../services/queryService';
import { YAService } from '../../../services/yaService';
import { AuthorizationType } from '../../../types/config';
import { withQuery } from '../../../utils';
import { FIELD_LENGTH, required } from '../../../utils/Form/formik-validation';
import { Button } from '../../Button/Button';
import { Input } from '../../FormComponents/Input';

const validationSchema = Yup.object().shape({
  userId: Yup.string().required(required).max(FIELD_LENGTH.login),
  password: Yup.string().required(required).max(FIELD_LENGTH.password),
});

const initialValues = {
  userId: '',
  password: '',
};

type FormFields = {
  userId: string;
  password: string;
};

type FormProps = {
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ loading, error }) => (
  <Form noValidate>
    <div className="form-control-row">
      <Field placeholder={messages.login} name="userId" type="text" component={Input} />
    </div>

    <div className="form-control-row">
      <Field placeholder={messages.password} name="password" type="password" component={Input} />
    </div>

    <Button
      type="submit"
      loading={loading}
      className="_primary _large _wide"
      text={messages.enter}
      data-action={YAService.eventTreeToString({
        loginForm: 'signIn',
      })}
    />

    {error && <p className="error-message">{error}</p>}
  </Form>
);

export const AuthForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const config = useStore(Config$);
  const modalsState = useStore(Modals$);
  const { refetch: fetchProfile } = useProfile(false);

  const onSubmit =
    config.authorizationMode === AuthorizationType.Cookies
      ? (values: FormFields) => {
          setLoading(true);
          GAService.sendEvent('ButtonAutorization');
          accountApi
            .login(values)
            .then((result) => {
              // @ts-ignore
              const { errorMessage, success } = result;

              if (success) {
                fetchProfile();
                modalsState.data?.onSuccess();
                modalApi.hide('');
              } else {
                setError(errorMessage);
              }
            })
            .catch(() => {
              setError(messages.enter);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      : (values: FormFields) => {
          let authData = `grant_type=password&username=${encodeURIComponent(
            values.userId,
          )}&password=${encodeURIComponent(values.password)}&client_id=${config.clientId}`;

          if (config.clientSecret) {
            authData += `&client_secret=${config.clientSecret}`;
          }

          setLoading(true);
          GAService.sendEvent('ButtonAutorization');

          auth(authData)
            .then((response) => {
              if (response) {
                AuthenticationService.accessToken = response.access_token;
                AuthenticationService.refreshToken = response.refresh_token;

                accountApi.getAccount('').then((result) => {
                  // @ts-ignore
                  const { success } = result;

                  if (success) {
                    fetchProfile();
                    modalsState.data?.onSuccess();
                    modalApi.hide('');

                    YAService.sendEvent(
                      YAService.eventTreeToString({
                        loginForm: {
                          signIn: `loginSuccess: ${values.userId}`,
                        },
                      }) as string,
                    );
                  } else {
                    setLoading(false);

                    const error = messages.generalError;

                    YAService.sendEvent(
                      YAService.eventTreeToString({
                        loginForm: {
                          signIn: `loginError: ${error}`,
                        },
                      }) as string,
                    );

                    setError(error);
                  }
                });
              } else {
                const error = messages.error;
                YAService.sendEvent(
                  YAService.eventTreeToString({
                    loginForm: {
                      signIn: `loginError: ${error}`,
                    },
                  }) as string,
                );
                setError(error);
              }
            })
            .finally(() => {
              setLoading(false);
            });
        };

  // end of auth

  const toOnboadring = () => {
    const path = PAGE_ONBOARDING;

    GAService.sendEvent('ButtonOpenDepo');

    const params = {
      [UTM_PARAMS.REF_ID]: QueryService.refId,
      [UTM_PARAMS.UTM_SOURCE]: QueryService.utmSource,
      [UTM_PARAMS.UTM_CAMPAIGN]: QueryService.utmCampaign,
      [UTM_PARAMS.UTM_CONTENT]: QueryService.utmContent,
      [UTM_PARAMS.UTM_MEDIUM]: QueryService.utmMedium,
      [UTM_PARAMS.UTM_TERM]: QueryService.utmTerm,
    };

    const url = withQuery(params)(path);

    const win = window.open(url, '_blank');
    win?.focus();
  };

  return (
    <div>
      <Button
        onClick={toOnboadring}
        loading={loading}
        className="_primary _large _wide"
        text={messages.openAccount}
        data-action={YAService.eventTreeToString({
          loginForm: 'openAccount',
        })}
      />
      <div className="modal__text">{messages.enterIntoAccount}</div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(props) =>
          React.createElement(FormTemplate, {
            ...props,
            loading,
            error,
          })
        }
      </Formik>
      <BrokerIcon />
    </div>
  );
};
