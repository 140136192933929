/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useState } from 'react';

import { useStore } from 'effector-react';

import { messages } from './messages';
import { bindInitiate } from '../../../api';
import { POP_UP } from '../../../const';
import { modalApi, Modals$ } from '../../../effector/modals';
import { Button } from '../../Button/Button';

const AutoFollowingAccept = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const modalsState = useStore(Modals$);

  const confirm = useCallback(async () => {
    const binding = {
      ...modalsState.data,
      sellAssestAgreement: true,
    };

    setLoading(true);
    try {
      const { errorMessage, success, response } = await bindInitiate(binding);

      if (success) {
        const popupData = {
          bindingId: response,
          text: binding.text,
        };
        modalApi.show({
          modalId: POP_UP.AUTO_FOLLOWING,
          data: popupData,
        });
      } else {
        setErrorMessage(errorMessage);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [modalsState]);

  const cancel = useCallback(async () => {
    const binding = {
      ...modalsState.data,
      sellAssestAgreement: false,
    };

    setLoading(true);
    try {
      const { errorMessage, success } = await bindInitiate(binding);

      if (success) {
        modalApi.show({
          modalId: POP_UP.ADD_STRATEGY,
          data: {},
        });
      } else {
        setErrorMessage(errorMessage);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [modalsState]);

  return (
    <div>
      <div className="modal__text-title">{messages.title}</div>
      <p className="modal__text-content">{messages.description}</p>
      {errorMessage && <p className="error-message" dangerouslySetInnerHTML={{ __html: errorMessage }} />}
      <div className="modal-strategy__buttons">
        <div className="modal-strategy__buttons-row">
          <div className="modal-strategy__buttons-col">
            <div>
              <Button
                className="_primary _large _wide"
                text={messages.selectAnotherAccount}
                loading={loading}
                onClick={cancel}
              />
            </div>
          </div>
          <div className="modal-strategy__buttons-col">
            <div>
              <Button className="_primary _large _wide" text={messages.accept} loading={loading} onClick={confirm} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoFollowingAccept;
