import { Author } from './author';
import { ChartPoint } from './chartPoint';
import { Currency } from './currency';
import { Duration } from './duration';
import { Index } from './index';
import { InvestProfile } from './investProfile';
import { Market } from './market';
import { PriceCategory } from './priceCategory';
import { Service } from './service';
import { StrategyComission } from './strategyComission';
import { StrategyPosition, StrategyPositionV2 } from './strategyPosition';
import { Tool } from './tool';
import { Nullable } from './utils';

export type Range = {
  start: number;
  end: number;
};

export type Label = {
  id: number;
  text: string;
  tooltip: string;
  foreColor: string;
  backColor: string;
};

type Board = {
  id: number;
  code: string;
  name: string;
  description: string;
};

export type Test = {
  id: string;
  name: string;
};

export enum ServiceKind {
  AF = 1,
  AC = 2,
}

type Campaign = {
  serviceKind: ServiceKind;
  campaignName: string;
  tariffDisplayValue: string;
  tariffInfo: string;
  tariffDescription: string;
};

export type Strategy = {
  id: string;
  friendlyUrl: string;
  name: string;
  maxInstrumentFraq?: number;
  autoconsult: boolean;
  tradesFrequency: string;
  autofollow: boolean;
  iis: boolean;
  rating: number;
  displayRating: number;
  tarif: number;
  showFullPortfolio: boolean;
  index: Index;
  positions: StrategyPosition[];
  indexId?: number;
  plStartDate?: string;
  activationComis: string;
  priceAC: string;
  priceAF: string;
  profitDescription?: string;
  forQualifiedInvestorsOnly: boolean;
  isClientAttached: boolean;
  positionsCount: number;
  tools: Tool[];
  markets: Market[];
  services: Service[];
  priceCategory: PriceCategory;
  history: ChartPoint[];
  comissionObjects: StrategyComission[];
  price?: string;
  chartComment?: string;
  estimatedProfit: number;
  estimatedRisk: number;
  subscriptionThreshold: number;
  subscriptionThresholdRub: number;
  minInvestProfile: InvestProfile;
  currency: Currency;
  pictureBase64?: string;
  pictureFormat: string;
  descriptionHtml: string;
  disclaimer: string;
  infoHtml: string;
  infoParts: string;
  author: Author;
  toolDrawndown: number;
  testMode: boolean;
  duration: Duration;
  actualProfit: number;
  startDate: string;
  minInvestCalculation: number;
  actualDrawdown: number;
  order: number;
  capacity: number;
  displayCapacity: string;
  isAlgostrategy: boolean;
  maxIndustryWeight: number;
  maxPositionWeight: number;
  leverage: number;
  open: boolean;
  recommended: boolean;
  unbindNote: Nullable<string>;
  seoTitle: string;
  seoDescription: string;
  videoUrl: string;
  strategyBoards: Board[];
  campaigns: Nullable<Campaign[]>;
  requiredTests: Nullable<Test[]>;
  requiredTestsLink: Nullable<string>;
  isAllowed: boolean;
  isMarginalRequired: boolean;
  riskReasons: {
    topic: string;
    description: string;
  }[];
};

type NQITest = {
  code: string;
  name: string;
};

export type StrategyV2 = {
  demand: number;
  icon: string | null;
  riskLevel: number;
  historicalProfitValue: number;
  historicalProfitDurationUnits: string;
  historicalProfitDuration: number;
  kinds: number[];
  nqiTests: NQITest[];
  id: string;
  name: string;
  currencyName: string;
  currencyCode: string;
  currencySymbol: string;
  profitValue: number;
  subscriptionThreshold: number;
  subscriptionThresholdRub: number;
  minInvestProfileName: string;
  minInvestProfileId: string;
  minInvestProfileNum: number;
  durationName: string;
  durationDays: number;
  totalPositionCount: number;
  positions: StrategyPositionV2[] | null;
  imageUrl: string | null;
  backColor: string | null;
  authorId: number;
  authorFirstName: string;
  authorLastName: string;
  authorPosition: string | null;
  authorImageUrl: string | null;
  isQualificationRequired: boolean;
  isMarginalRequired: boolean;
  friendlyUrl: string;
  publishingDate: string;
};
