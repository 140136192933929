/* eslint-disable @typescript-eslint/ban-ts-comment */
class GAService {
  _gtag = null;
  constructor() {
    //@ts-ignore
    this._gtag = global.gtag;
  }

  get ga() {
    return this._gtag;
  }

  sendEvent = (eventAction: string) => {
    //@ts-ignore
    this._gtag?.('event', eventAction, {
      event_category: 'Web',
      event_callback: () => {
        console.log('ga sent event');
      },
    });
  };
}

const GAServiceInstance = new GAService();

export { GAServiceInstance as GAService };
