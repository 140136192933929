/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, MouseEvent, PropsWithChildren } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { YAService } from '../../services/yaService';

type Props = {
  link: string;
  children: React.ReactNode;
  className?: string;
  title?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

type LinkProps = PropsWithChildren & {
  tag?: string;
  href?: string;
  target?: string;
  rel?: string;
  to?: string;
  className?: string;
  onClick?: (e?: any) => void;
};

export const Link: React.FC<Props> = ({ children, className, link, onClick, ...rest }) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      const actionName = e.currentTarget.dataset.action;
      if (actionName) {
        YAService.sendEvent(actionName);
      }
      onClick?.(e);
    },
    [onClick],
  );

  const componentProps: LinkProps = {};

  const route = link || '#';

  const isHttp = route && typeof route === 'string' && route.indexOf('http') === 0;

  // @ts-ignore
  const Component: React.FC<LinkProps> = route && isHttp ? 'a' : RouterLink;

  if (route) {
    if (isHttp) {
      componentProps.tag = 'a';
      componentProps.href = route;
      componentProps.target = '_blank';
      componentProps.rel = 'noopener noreferrer';
    } else {
      componentProps.to = route;
    }

    componentProps.onClick = handleClick;
  }

  return (
    <Component className={className} {...componentProps} {...rest}>
      {children}
    </Component>
  );
};
