/* eslint-disable @typescript-eslint/ban-ts-comment */
import { polyfill } from 'es6-promise';

import 'whatwg-fetch';

import { get, post, deleteRequest, profileApi, agreementApi, moneyApi, faqApi } from './apiHelper';
import { AgreementListResponse } from '../types/agreement';
import { ChartMap } from '../types/chartData';
import { MoneyResponse } from '../types/money';
import { Profile } from '../types/profile';
import { ProfitResult } from '../types/profit';
import { Recommendation, RecommendationDetails } from '../types/recommendation';
import { StrategyV2 } from '../types/strategy';
import { StrategyStatus } from '../types/strategyStatus';
import { Nullable, ResponseV1, ResponseV2, ResponseV2WithPages } from '../types/utils';

polyfill();

// @ts-ignore
export const login = (data) => post('account/login', data);

export const logOut = () => get('account/logout');

export const getAccount = () => get('account/clientdetails');

export const refreshAccount = () => get('account/refresh');

export const refreshAccountV2 = (strategyId: string) => get(`v2/account/refresh/${strategyId}`);

// @ts-ignore
export const getAuthorsStatistic = () => get('authors/statistics');

// @ts-ignore
export const getAuthorsDocuments = () => get('authors/docsbygroup');

// @ts-ignore
export const getAuthorDocument = (data) => post('authors/downloaddoc', data);

// @ts-ignore
export const deleteUploadedAuthorDocument = (data) => post('authors/deletedoc', data);

// @ts-ignore
export const sendAuthorRequest = (data) => post('authors/requestnew', data);

// @ts-ignore
export const resetuploadedfiles = () => get('authors/resetuploadedfiles');

// @ts-ignore
export const getRecentDeals = (strategyId) => get(`strategies/trades/${strategyId}/3`);

// @ts-ignore
export const getChartData = (strategyId, period) => get(`v3/strategies/charts/${strategyId}/${period}`);

export const getStrategiesProfits: () => Promise<ResponseV1<ProfitResult>> = () => get('strategies/profits');

export const getStrategiesV2: () => Promise<ResponseV1<StrategyV2[]>> = () => post('v2/strategies', {});

export const getChartsV2: () => Promise<ResponseV1<ChartMap>> = () => get('v2/strategies/smallcharts');
// @ts-ignore
export const getStrategy = (id) => get(`strategies/${id}`);

export const getInvestProfiles = () => get('investprofile');

export const getMarkets = () => get('markets');

export const getTools = () => get('tools');

export const getTop = () => get('v2/strategies/top');

export const getRecommended = () => get('strategies/recommended');

// @ts-ignore
export const getAuthor = (id) => get(`authors/name/${id}`);
// @ts-ignore
export const getAuthorStrategies = (id) => get(`v2/strategies/byauthor/${id}`);
// @ts-ignore
export const getAuthorArticles = (params) => get(`analytics/author/${params.id}/${params.count}`);

// @ts-ignore
export const getArticles = (params) => post(`analytics/last_cat`, params);

// @ts-ignore
export const getLastArticles = (count? = 3) => get(`analytics/last/${count}`);

// @ts-ignore
export const getLastDeals = (count? = 3) => get(`lastSignals/${count}`);

// @ts-ignore
export const getArticle = (id) => get(`analytics/${id}`);

// @ts-ignore
export const getArticleStrategies = (id) => get(`v2/analytics/${id}/strategies`);

// @ts-ignore
export const getStrategyArticles = (id, count? = 3) => get(`analytics/strategy/${id}/${count}`);

export const getCategories = () => get('analytics/categories');

export const getBanners = (utm: Nullable<string> = '') => get(`banners/utm/${utm}`);

export const getLandingBanners = () => get('banners/landing');

export const setIirForAgreement = (data: { agreementGuid: string; sendIirSms?: boolean; sendIirEmail?: boolean }) =>
  post('account/setiirtransport', data);

// @ts-ignore
export const unsubscribe = (data) => deleteRequest(`analytics/subscribe/${data.code}`);

// @ts-ignore
export const getStrategyProfits = (id) => get(`strategies/plperiods/${id}`);

export const getStrategySecurities = (id: string) => get(`strategies/securities/${id}`);

export type ConfirmStrategyAction = {
  bindingId: string;
  confirmationCode?: string;
  documentFormat?: string;
  documentKind?: string;
};

export type InitialStrategyAction = {
  agreementId: string;
  strategyId: string;
  serviceKind?: number;
};

export const bindInitiate = (params: InitialStrategyAction) => post('v2/strategies/bindinitiate', params);

export const bindGetDocument = (params: ConfirmStrategyAction) => post('v2/strategies/bindgetdocument', params);

export const bindSendSms = (params: ConfirmStrategyAction) => post('v2/strategies/bindsendsms', params);

export const bindConfirm = (params: ConfirmStrategyAction) => post('v2/strategies/bindconfirm', params);

export const unBindInitiate = (params: InitialStrategyAction) => post('v2/strategies/unbindinitiate', params);

export const unBindGetDocument = (params: ConfirmStrategyAction) => post('v2/strategies/unbindgetdocument', params);

export const unBindSendSms = (params: ConfirmStrategyAction) => post('v2/strategies/unbindsendsms', params);

export const unBindConfirm = (params: ConfirmStrategyAction) => post('v2/strategies/unbindconfirm', params);

export const getStrategyStatus: (agreementId: string) => Promise<ResponseV2<StrategyStatus>> = (agreementId) =>
  get(`v2/account/${agreementId}/strategystatus`);

export const getConfig = () => get('config');

type PageParams = {
  page: number;
  pageSize: number;
};

export type RequestRecommendationParams = PageParams & {
  agreementId: string;
};

export const getAccountRecommendations: (
  params: RequestRecommendationParams,
) => Promise<ResponseV2WithPages<Recommendation>> = (params) =>
  get(`v2/account/recommendations/account/${params.agreementId}/${params.pageSize}/${params.page}`);

export const getAccountRecommendationDetails: (id: number) => Promise<ResponseV2<RecommendationDetails[]>> = (id) =>
  get(`v2/account/recommendations/${id}/positions`);

// PROFILE API

export const getProfile: () => Promise<{ response: { profile: Profile } }> = () =>
  profileApi.get(`/investProfile/clientprofile`);

export const getQuestions = () => profileApi.post(`/investProfile/createprocess`);

// @ts-ignore
export const sendQuestions = (data) => profileApi.post(`/processes/${data.processId}/calculateprofile`, data.questions);

// @ts-ignore
export const getProfileDoc = (data) => profileApi.get(`/processes/${data.processId}/documents/${data.documentId}`);

// @ts-ignore
export const startProfileConfirmation = (data) => profileApi.post(`/processes/${data.processId}/createconfirmation`);

// @ts-ignore
export const startProfileConfirmationSendSms = (data) =>
  profileApi.post(`/processes/${data.processId}/confirmations/${data.confirmationId}/sendsms`);

// @ts-ignore
export const confirmProfile = (data) =>
  profileApi.post(`/processes/${data.processId}/confirmations/${data.confirmationId}/confirmprocess`, {
    otp: data.otp,
  });

// AGREEMENT API
export const getAgreementList: () => Promise<AgreementListResponse> = () =>
  agreementApi.get('/client/agreementlist/v2');

// MONEY API
export const getMoney: () => Promise<MoneyResponse> = () => moneyApi.get('/client/positions-stream-rest/money/v1');

// FAQ API
export const getFaq = () =>
  faqApi.get('/web/faq/api/v1/categories/145?serviceConsumerId=d9184e4c-9e3f-43c8-b08f-a7f6e85424bc');
