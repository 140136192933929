import { FC, useCallback } from 'react';

import cn from 'classnames';
import { useStore } from 'effector-react';

import { messages } from './messages';
import { modalApi } from '../../../effector/modals';
import { Strategy$ } from '../../../effector/strategy';
import { Icon } from '../../Icon';

type Props = {
  className?: string;
};

export const Attention: FC<Props> = ({ className }) => {
  const strategy = useStore(Strategy$);

  const onClick = useCallback(() => {
    localStorage.setItem('profile', strategy?.minInvestProfile?.id?.toString() ?? '');

    window.open('https://lk.bcs.ru/invest-profile', '_blank');
    modalApi.hide('');
  }, [strategy]);

  return (
    <div className={cn('modal-attention', className)}>
      <div className="modal-attention__icon">
        <Icon className="modal-attention__svg" name="attention" width={136} height={109} />
      </div>
      <div className="modal-attention__text">
        {messages.title}
        <br />
        {messages.description}{' '}
        <span style={{ textDecoration: 'none', cursor: 'pointer' }} className="modal__link" onClick={onClick}>
          {messages.link}
        </span>
      </div>
    </div>
  );
};
