type Tree = {
  [key: string]: Tree | string | Array<Tree | string>;
};

class YAService {
  observer: IntersectionObserver | null = null;

  subscribeToObserver() {
    const elements = document.querySelectorAll('[data-observable]');

    elements.forEach((obs) => {
      this.observer?.observe(obs);
    });
  }

  initIntersection() {
    const options = {
      threshold: 0.1,
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.subscribeToObserver();
  }

  handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        const event = entry.target.getAttribute('data-observable');
        if (event) {
          this.sendEvent(event);
        }
        // console.log('observer-' + entry.target.getAttribute('data-observable'));
        observer.unobserve(entry.target);
      }
    });
  };

  eventTreeToString(obj: Tree) {
    try {
      const event = JSON.stringify(obj);
      return event;
    } catch (e) {
      console.warn('Event error: parsing event event to string');
    }
  }

  stringToTreeEvent(str: string) {
    try {
      const event: Tree = JSON.parse(str);
      return event;
    } catch (e) {
      console.warn('Event error: parsing string to event');
    }
  }

  sendEvent = (eventAction: string) => {
    const event = this.stringToTreeEvent(eventAction);
    if (event) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      window.ym?.(55364233, 'params', event);
    }
    // console.log(event);
  };
}

const YAServiceInstance = new YAService();

export { YAServiceInstance as YAService };
