import { Component, PropsWithChildren, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { messages } from './messages';

type Props = PropsWithChildren & {
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
};

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key]);

  return (
    <ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundaryInner>
  );
};

class ErrorBoundaryInner extends Component<Props> {
  componentDidCatch() {
    this.props.setHasError(true);
  }

  render() {
    if (this.props.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1 className="errorH1">{messages.title}</h1>
          <div className="errorH2">{messages.description}</div>
        </div>
      );
    }

    return this.props.children;
  }
}
