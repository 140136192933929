export const multiplier = 1000;

export const CONNECTION_TYPE = {
  AUTOFOLLOW: 'Автоследование',
  RECOMMENDATIONS: 'Автоконсультирование',
};

export const POP_UP = {
  AUTHORIZATION: 'auth',
  REMOVE_STRATEGY: 'removeStrategy',
  AUTO_FOLLOWING: 'autoFollowing',
  AUTO_FOLLOWING_ACCEPT: 'autoFollowingAccept',
  REMOVE_AUTO_FOLLOWING: 'removeAutoFollowing',
  ADD_STRATEGY: 'addStrategy',
  RECOMMENDATIONS: 'recommendations',
  ATTENTION: 'attention',
  APPROVAL: 'approval',
  SUBSCRIBE: 'subscribe',
  NAV: 'nav',
};

export const SEO_CONTENT = {
  '/': {
    title: 'Автоследование Fintarget — подключите инвестиционные стратегии и торгуйте какпрофессионал',
    meta: [
      {
        name: 'description',
        content:
          'Готовые торговые стратегии от экспертов Fintarget. Зарабатывайте на фондовом рынке какпрофессиональный трейдер. Большой выбор стратегий в зависимости от уровня. Стабильная доходность и защита от рисков',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href,
      },
    ],
  },
  strategies: {
    title: 'Стратегии Fintarget от ведущих аналитиков и экспертов рынка. Автоследование и автоконсультирование',
    meta: [
      {
        name: 'description',
        content:
          'Десятки стратегий для инвесторов с любым профилем и уровнем дохода. Фильтр по типуфинансовых инструментов, уровню риска, целевой доходности. Российский и американский рынки',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href,
      },
    ],
  },
  faq: {
    title: 'Что такое автоследование? Как выбрать торговую стратегию? Как инвестировать без опыта изнаний?',
    meta: [
      {
        name: 'description',
        content:
          'Ответы на частые вопросы. Что такое автоконсультирование? Какие есть риски торговых стратегий? Что такое инвестиционный профиль? О проекте Брокера No 1 — Fintarget',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href,
      },
    ],
  },
  personal: {
    title: 'Кабинет клиента / Fintarget.ru - Автоследование в БКС Брокер',
    link: [
      {
        rel: 'canonical',
        href: window?.location.href,
      },
    ],
    meta: [
      {
        name: 'description',
        content: 'Персональный кабинет клиента - управление услугами и стратегиями',
      },
    ],
  },
  blogs: {
    title: 'Новости и аналитика от экспертов Fintarget',
    meta: [
      {
        name: 'description',
        content:
          'Еженедельный обзор рынка и стратегий Fintarget. Актуальная стратегия недели. Новости и экспертное мнение. Автоконсультирование и автоследование',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href,
      },
    ],
  },
  experts: {
    title: 'Разместите инвестиционную стратегию на Fintarget и получите дополнительный доход',
    meta: [
      {
        name: 'description',
        content:
          'Выгодные условия сотрудничества для авторов стратегий Fintarget. Поддержка от Брокера No1 на рынке. Профессиональная экспертиза рисков вашей стратегии. Широкий круг клиентов',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href,
      },
    ],
  },
  about: {
    title: 'Магазин инвестиционных стратегий Fintarget. Инвестируйте как профессионал без опыта на фондовом рынке',
    meta: [
      {
        name: 'description',
        content:
          'Проект Брокера No 1 в рейтинге Московской биржи. Автоследование Fintarget повторяет сделки за авторами стратегии с многолетним опытом торговли. Контролируйте свой результат в личном кабинете. Если вы автор — разместите стратегию и получайте доход',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: window?.location.href,
      },
    ],
  },
};

export const HIGHCHART_SETTINGS = {
  lang: {
    contextButtonTitle: 'Вызвать контекстное меню',
    decimalPoint: '.',
    downloadJPEG: 'Скачать JPEG картинку',
    downloadPDF: 'Скачать PDF документ',
    downloadPNG: 'Скачать PNG картинку',
    downloadSVG: 'Скачать SVG картинку',
    drillUpText: 'Назад в {series.name}',
    invalidDate: '',
    rangeSelectorZoom: '',
    loading: 'Загрузка ...',
    months: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
    noData: 'Данные для показа отсутствуют',
    numericSymbols: ['К', 'М', 'Г', 'Т', 'П', 'Э'],
    printChart: 'Распечатать график',
    resetZoom: 'Сбросить увеличение',
    resetZoomTitle: 'Сбросить увеличение level 1:1',
    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    shortWeekdays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    thousandsSep: ' ',
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  },
};
