import { FC } from 'react';

import classNames from 'classnames';

import { Contact } from './Contact/Contact';
import { messages } from './messages';
import { FooterNav } from './Nav';
import { products } from '../../const/products';
import { PAGE_ABOUT, PAGE_ARTICLES, PAGE_EXPERTS, PAGE_STRATEGIES, PAGE_SUPPORT_FAQ } from '../../Pages/Routes';
import { YAService } from '../../services/yaService';
import { Link } from '../Link';
import { Logo } from '../Logo';
import { ReactComponent as ArrowLinkIcon } from '../PageHeader/arrow-link.svg';

export const PageFooter: FC<{ className?: string }> = ({ className }) => (
  <footer
    className={classNames('page-footer', className)}
    data-observable={YAService.eventTreeToString({
      footer: 'view',
    })}
  >
    <div className="page-footer__container">
      <div className="page-footer__main">
        <Logo className="_dark _small page-footer__logo" place="footer" />
        <FooterNav
          items={[
            {
              link: PAGE_STRATEGIES,
              text: messages.linkStrategies,
              action: YAService.eventTreeToString({
                footer: 'strategy',
              }),
            },
            {
              link: PAGE_ARTICLES,
              text: messages.linkAnalytics,
              action: YAService.eventTreeToString({
                footer: 'analytics',
              }),
            },
            {
              link: PAGE_EXPERTS,
              text: messages.linkAuthors,
              action: YAService.eventTreeToString({
                footer: 'forAuthors',
              }),
            },
            {
              link: PAGE_ABOUT,
              text: messages.linkProject,
              action: YAService.eventTreeToString({
                footer: 'about',
              }),
            },
            {
              link: PAGE_SUPPORT_FAQ,
              text: messages.linkFaq,
              action: YAService.eventTreeToString({
                footer: 'faq',
              }),
            },
          ]}
        />
        <div className="page-footer__projects">
          <div className="page-footer__projects-title">Проекты БКС</div>
          {products.map(({ label, link }) => (
            <Link key={label} link={link} className="page-footer__projects-item">
              {label}
              <ArrowLinkIcon className="page-footer__projects-item-icon" />
            </Link>
          ))}
        </div>
        <Contact className="page-footer__contact" />
      </div>

      <div className="page-footer__copyright">
        <p className="page-footer__text">
          Адрес для вопросов и предложений по сайту: <a href="mailto:info@fintarget.ru">info@fintarget.ru</a>
        </p>
        <p className="page-footer__text">
          Данные являются биржевой информацией, обладателем (собственником) которой является ПАО Московская Биржа.
          Распространение, трансляция или иное предоставление биржевой информации третьим лицам возможно исключительно в
          порядке и на условиях, предусмотренных порядком использования биржевой информации, предоставляемой ОАО
          Московская Биржа. ООО «Компания БКС», лицензия №154-04434-100000 от 10.01.2001 на осуществление брокерской
          деятельности. Выдана ФСФР. Без ограничения срока действия.
          <br /> 129110, Москва, Проспект Мира, 69, стр. 1, 3 подъезд.
        </p>
        <p className="page-footer__text">
          Предоставление индивидуальных инвестиционных рекомендаций (ИИР) в соответствии со стратегией предоставления
          ИИР, представленной на сайте, осуществляется ООО «Компания БКС» с использованием программного обеспечения
          (программы для ЭВМ) «FinTarget» в рамках Договора об инвестиционном консультировании, заключаемого с ООО
          «Компания БКС», при условии акцепта клиентом услуги «Автоконсультирование» или услуги «Автоследование» в
          порядке, установленном Договором об инвестиционном консультировании. ООО «Компания БКС» является
          профессиональным участником рынка ценных бумаг, осуществляющим деятельность по инвестиционному
          консультированию. ООО «Компания БКС» включено Банком России в Единый реестр инвестиционных советников
          21.12.2018 г. Программное обеспечение (программа для ЭВМ) «FinTarget» версия 2.0. 17.12.2021 г. аккредитовано
          Национальной ассоциацией участников фондового рынка в качестве программы автоконсультирования и программы
          автоследования, посредством которой осуществляется предоставление ИИР автоматизированным способом на основе
          заданных условий (с ограничением участия человека сбором и вводом информации в данное программное
          обеспечение), а также которая позволяет автоматизированным способом преобразовывать предоставленную ИИР в
          поручение брокеру на совершение сделки с финансовыми инструментами, предусмотренными ИИР. Клиент
          самостоятельно принимает решение о необходимости получения им услуг ООО «Компания БКС» по инвестиционному
          консультированию, основываясь на своих знаниях и опыте инвестирования на финансовых рынках. С полной
          информацией об ООО «Компания БКС», об условиях Договора об инвестиционном консультировании и условиях оказания
          услуги «Автоконсультирование» или услуги «Автоследование», условий вознаграждения ООО «Компания БКС» за
          оказание данных услуг, и иной подлежащей раскрытию информации, включая ссылку на страницу на которой можно
          оставить обращение (жалобу), Вы можете ознакомиться на официальном сайте ООО «Компания БКС» по адресу{' '}
          <Link
            link="https://bcs.ru/regulatory"
            className="page-footer__link"
            data-action={YAService.eventTreeToString({
              footer: 'disclaimer',
            })}
          >
            https://bcs.ru/regulatory
          </Link>
          .
        </p>
        <p className="page-footer__text">
          Данная информация не является индивидуальной инвестиционной рекомендацией, и финансовые инструменты либо
          сделки, упомянутые в ней, могут не соответствовать Вашему финансовому положению, цели (целям) инвестирования,
          допустимому риску, и (или) ожидаемой доходности. ООО «Компания БКС» не несет ответственности за возможные
          убытки в случае совершения сделок либо инвестирования в финансовые инструменты, упомянутые в данной
          информации. Информация не может рассматриваться как публичная оферта, предложение или приглашение приобрести,
          или продать какие-либо ценные бумаги, иные финансовые инструменты, совершить с ними сделки. Информация не
          может рассматриваться в качестве гарантий или обещаний в будущем доходности вложений, уровня риска, размера
          издержек, безубыточности инвестиций. Результат инвестирования в прошлом не определяет дохода в будущем. Не
          является рекламой ценных бумаг. Перед принятием инвестиционного решения Инвестору необходимо самостоятельно
          оценить экономические риски и выгоды, налоговые, юридические, бухгалтерские последствия заключения сделки,
          свою готовность и возможность принять такие риски. Клиент также несет расходы на оплату брокерских и
          депозитарных услуг, подачи поручений по телефону, иные расходы, подлежащие оплате клиентом. Полный список
          тарифов ООО «Компания БКС» приведен в{' '}
          <Link
            link="https://cdn.bcs.ru/static/broker/f/reg/attachment-11/tarif-240518.pdf"
            className="page-footer__link"
            data-action={YAService.eventTreeToString({
              footer: 'disclaimer',
            })}
          >
            приложении № 11
          </Link>{' '}
          к Регламенту оказания услуг на рынке ценных бумаг ООО «Компания БКС». Перед совершением сделок вам также
          необходимо ознакомиться с:{' '}
          <Link
            link="https://cdn.bcs.ru/static/broker/f/reg/attachment-14/pril_riski-240501.pdf"
            className="page-footer__link"
            data-action={YAService.eventTreeToString({
              footer: 'disclaimer',
            })}
          >
            уведомлением о рисках
          </Link>
          , связанных с осуществлением операций на рынке ценных бумаг;{' '}
          <Link
            link="https://cdn.bcs.ru/static/broker/f/reg/attachment-07/nepol_pokr_risk-240401.pdf"
            className="page-footer__link"
            data-action={YAService.eventTreeToString({
              footer: 'disclaimer',
            })}
          >
            информацией о рисках клиента
          </Link>
          , связанных с совершением сделок с неполным покрытием, возникновением непокрытых позиций, временно непокрытых
          позиций;{' '}
          <Link
            link="https://cdn.bcs.ru/static/broker/f/reg/risk.pdf"
            className="page-footer__link"
            data-action={YAService.eventTreeToString({
              footer: 'disclaimer',
            })}
          >
            заявлением, раскрывающим риски
          </Link>
          , связанные с проведением операций на рынке фьючерсных контрактов, форвардных контрактов и опционов;{' '}
          <Link
            link="https://cdn.bcs.ru/static/broker/f/reg/attachment-14/pril_riski_is_id-240401.pdf"
            className="page-footer__link"
            data-action={YAService.eventTreeToString({
              footer: 'disclaimer',
            })}
          >
            декларацией о рисках
          </Link>
          , связанных с приобретением иностранных ценных бумаг.
        </p>
      </div>
    </div>
  </footer>
);
