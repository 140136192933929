export enum AuthorizationType {
  Cookies = 'Cookies',
}

export type Config = {
  authorizationMode: AuthorizationType;
  authorizationUrl: string;
  profileApiUrl: string;
  agreementApiUrl: string;
  moneyApiUrl: string;
  faqApiUrl: string;
  clientId: string;
  clientSecret: string;
};
