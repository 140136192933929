import React from 'react';

import classNames from 'classnames';

import { PAGE_STRATEGIES } from '../../../Pages/Routes';
import { YAService } from '../../../services/yaService';
import { Profit } from '../../../types/profit';
import { getProgressSymbol } from '../../../utils';
import { Link } from '../../Link';

type Props = Profit;

export const Ticker: React.FC<Props> = ({ friendlyUrl, strategyName, profit }) => {
  return (
    <div className="tickers__item">
      <Link
        link={`${PAGE_STRATEGIES}/${friendlyUrl}`}
        className="tickers__link"
        data-action={YAService.eventTreeToString({
          header: `runningLine: ${strategyName}, ${profit}`,
        })}
      >
        {strategyName}
      </Link>
      <div
        className={classNames('tickers__cell', {
          _up: profit > 0,
          _down: profit < 0,
        })}
      >
        {getProgressSymbol(profit)}
        {Math.abs(profit)}%
      </div>
    </div>
  );
};
