import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { useStore } from 'effector-react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Burger } from './Burger';
import { POP_UP } from '../../../const';
import { Account$, accountApi } from '../../../effector/clientAccounts';
import { modalApi, Modals$ } from '../../../effector/modals';
import {
  PAGE_ABOUT,
  PAGE_ARTICLES,
  PAGE_EXPERTS,
  PAGE_HOME,
  PAGE_ONBOARDING,
  PAGE_PERSONAL,
  PAGE_STRATEGIES,
  PAGE_SUPPORT_FAQ,
} from '../../../Pages/Routes';
import { GAService } from '../../../services/gaService';
import { QueryService, UTM_PARAMS } from '../../../services/queryService';
import { YAService } from '../../../services/yaService';
import { Nullable } from '../../../types/utils';
import { withQuery } from '../../../utils';
import { Button } from '../../Button/Button';
import { messages } from '../messages';
import { HeaderNav } from '../Nav';

type SubMenuProps = {
  className: string;
  logOut: () => void;
};

const SubMenu: React.FC<SubMenuProps> = ({ logOut, className }) => (
  <div className={classNames('navbar__submenu', className)}>
    <ul className="navbar__submenu-list">
      <li className="navbar__submenu-item" onClick={logOut}>
        <span>{messages.exit}</span>
      </li>
    </ul>
  </div>
);

export const Navbar: React.FC = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [page, setPage] = useState<Nullable<Element>>(null);
  const modalsState = useStore(Modals$);

  const { account } = useStore(Account$);
  const navigate = useNavigate();
  const location = useLocation();

  const closeNavPopup = useCallback(() => {
    modalApi.hide('');
    page?.classList.remove('_menu-opened');
    document.documentElement.style.overflow = 'initial';
  }, [page]);

  useEffect(() => {
    closeNavPopup();
  }, [location.pathname, closeNavPopup]);

  useEffect(() => {
    setPage(document.querySelector('.page'));
  }, []);

  const handleHover = () => setIsOpened(true);
  const handleLeave = () => setIsOpened(false);

  const openNavPopup = () => {
    modalApi.show({
      modalId: POP_UP.NAV,
    });
    page?.classList.add('_menu-opened');
    document.documentElement.style.overflow = 'hidden';
  };

  const onLogOut = () => {
    accountApi.logOut('');
    navigate(PAGE_HOME);
  };

  const toOnboadring = () => {
    GAService.sendEvent('ButtonOpenDepo');

    const params = {
      [UTM_PARAMS.REF_ID]: QueryService.refId,
      [UTM_PARAMS.UTM_SOURCE]: QueryService.utmSource,
      [UTM_PARAMS.UTM_CAMPAIGN]: QueryService.utmCampaign,
      [UTM_PARAMS.UTM_CONTENT]: QueryService.utmContent,
      [UTM_PARAMS.UTM_MEDIUM]: QueryService.utmMedium,
      [UTM_PARAMS.UTM_TERM]: QueryService.utmTerm,
    };

    const url = withQuery(params)(PAGE_ONBOARDING);

    const win = window.open(url, '_blank');
    win?.focus();
  };

  const openAuth = () => {
    if (account) {
      navigate(PAGE_PERSONAL);
    } else {
      modalApi.show({
        modalId: POP_UP.AUTHORIZATION,
        data: {
          onSuccess: () => {
            navigate(PAGE_PERSONAL);
            closeNavPopup();
          },
        },
      });
    }

    page?.classList.remove('_menu-opened');
  };

  const menuItems = useMemo(() => {
    const items = [
      {
        link: PAGE_STRATEGIES,
        text: messages.linkStrategies,
        action: YAService.eventTreeToString({
          header: 'strategy',
        }),
      },
      {
        link: PAGE_ARTICLES,
        text: messages.linkAnalytics,
        action: YAService.eventTreeToString({
          header: 'analytics',
        }),
      },
      {
        link: PAGE_EXPERTS,
        text: messages.linkAuthors,
        action: YAService.eventTreeToString({
          header: 'forAuthors',
        }),
      },
      {
        link: PAGE_ABOUT,
        text: messages.linkProject,
        action: YAService.eventTreeToString({
          header: 'about',
        }),
      },
      {
        link: PAGE_SUPPORT_FAQ,
        text: messages.linkFaq,
        action: YAService.eventTreeToString({
          header: 'faq',
        }),
      },
    ];

    return items;
  }, []);

  return (
    <div className={classNames('navbar', { _opened: modalsState.nav })}>
      <div className="navbar__inner">
        <HeaderNav className="navbar__main-nav" items={menuItems} />
        <div className="navbar__open-bid-button">
          <Button
            onClick={toOnboadring}
            className="button _primaryNew _medium"
            text={messages.openAccount}
            data-action={YAService.eventTreeToString({
              header: 'openAccount',
            })}
          />
        </div>

        <div className="navbar__popover" onMouseLeave={handleLeave} onMouseEnter={handleHover}>
          <Button
            onClick={openAuth}
            className="button _primaryNew _medium"
            text={account ? messages.lk : messages.enter}
            data-action={YAService.eventTreeToString({
              header: account ? `account: ${account.phone}` : 'signIn',
            })}
          />

          {account && (
            <SubMenu
              logOut={onLogOut}
              className={classNames({
                'navbar__submenu-visible': isOpened,
              })}
            />
          )}
        </div>
      </div>
      <Burger className="navbar__burger" isActive={modalsState.nav} onOpen={openNavPopup} onClose={closeNavPopup} />
    </div>
  );
};
