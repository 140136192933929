import { FC, PropsWithChildren, useEffect } from 'react';

import cn from 'classnames';
import ReactModal from 'react-modal';

import { messages } from './messages';
import { YAService } from '../../services/yaService';

type Props = PropsWithChildren & {
  title?: string;
  isOpen: boolean;
  onRequestClose: () => void;
  onCrossClose?: () => void;
  className?: string;
  action?: string;
};
export const Modal: FC<Props> = ({ title, isOpen, children, className, action, onRequestClose, onCrossClose }) => {
  useEffect(() => {
    if (isOpen && action) {
      YAService.sendEvent(action);
    }
  }, [isOpen, action]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      bodyOpenClassName={'_modal-opened'}
      overlayClassName={'modal__overlay'}
      className={'modal__container'}
      portalClassName={cn('modal is-open', className)}
    >
      <header className="modal__header">
        {title && <div className="modal__title">{title}</div>}
        <button className="modal__close" type="button" onClick={onCrossClose || onRequestClose}>
          {messages.close}
        </button>
      </header>
      <div className="modal__content">{children}</div>
    </ReactModal>
  );
};
