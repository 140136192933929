import React, { useMemo, useState } from 'react';

import cs from 'classnames';
import { useField } from 'formik';

export const Input = ({ field: fieldProp, className, type, disabled, placeholder }) => {
  const [focus, setFocus] = useState(false);
  const [field, meta] = useField(fieldProp.name);

  const hasValue = useMemo(() => {
    return !!field.value;
  }, [field.value]);

  const isError = meta.touched && meta.error;
  const isDirty = meta.value !== meta.initialValue;

  const onFocus = () => setFocus(true);
  const onBlur = (e) => {
    field.onBlur(e);
    setFocus(false);
  };

  return (
    <label
      className={cs('form-control', className, {
        _focus: focus || isDirty || hasValue,
      })}
    >
      <input
        className={cs('form-control__field', {
          _invalid: isError,
          _disabled: disabled,
        })}
        type={type}
        placeholder={placeholder}
        required
        {...field}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {isError && <span className="form-control__message">{meta.error}</span>}
      <span className="form-control__placeholder">{placeholder}</span>
    </label>
  );
};
