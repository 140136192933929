import { createStore, createApi, createEffect, forward } from 'effector';

import { login, getAccount, refreshAccount, refreshAccountV2 } from '../api';
import { LOGOUT } from '../const/event';
import { AuthenticationService } from '../services/authenticationService';
import { Account } from '../types/account';
import { Profile } from '../types/profile';
import { RefreshedAccount } from '../types/refreshedAccount';
import { Nullable } from '../types/utils';

type TAccount$State = {
  account: Nullable<Account>;
  refreshedAccounts: RefreshedAccount[];
  profile: Nullable<Profile>;
  isFetchingProfile: boolean;
};

const initialState: TAccount$State = {
  account: undefined,
  refreshedAccounts: [],
  profile: undefined,
  isFetchingProfile: false,
};

export const Account$ = createStore<TAccount$State>(initialState);

const { setAccount, refresh, refreshV2, setFetching, reset, resetRefreshV2 } = createApi(Account$, {
  setAccount: (state, { result }) => {
    const { success, response } = result;

    if (success) {
      accountApi.fetching(true);
      accountApi.fetching(false);

      return { ...state, account: response };
    }

    return state;
  },
  setProfile: (state, { result }) => {
    return { ...state, profile: result.response.profile };
  },
  refresh: (state, { result }) => {
    const { success, response } = result;
    if (success) {
      return {
        ...state,
        account: response.account,
        profile: response.profile,
      };
    }
    return state;
  },
  refreshV2: (state, { result }) => {
    const { success, response } = result;
    if (success) {
      return {
        ...state,
        refreshedAccounts: response,
      };
    }
    return state;
  },
  setFetching: (state, value) => {
    return { ...state, isFetchingProfile: value };
  },
  reset: () => {
    AuthenticationService.clearAccessToken();
    AuthenticationService.clearRefreshToken();

    return initialState;
  },
  resetRefreshV2: (state) => {
    return {
      ...state,
      refreshedAccounts: [],
    };
  },
});

export const accountApi = {
  login: createEffect().use(login),
  logOut: createEffect(),
  getAccount: createEffect().use(getAccount),
  refreshAccounts: createEffect().use(refreshAccount),
  // eslint-disable-next-line @typescript-eslint/ban-types
  refreshAccountsV2: createEffect<string, {}, {}>().use(refreshAccountV2),
  fetching: createEffect(),
  resetRefreshV2: createEffect(),
};

forward({
  from: accountApi.login.done,
  to: setAccount,
});

forward({
  from: accountApi.getAccount.done,
  to: setAccount,
});

forward({
  from: accountApi.refreshAccounts.done,
  to: refresh,
});

forward({
  from: accountApi.refreshAccountsV2.done,
  to: refreshV2,
});

forward({
  from: accountApi.fetching,
  to: setFetching,
});

forward({
  from: accountApi.logOut,
  to: reset,
});

forward({
  from: accountApi.resetRefreshV2,
  to: resetRefreshV2,
});

window.addEventListener(LOGOUT, () => {
  accountApi.logOut('');
});
