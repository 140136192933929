import { FC } from 'react';

import { useStore } from 'effector-react';

import { messages } from './messages';
import { modalApi, Modals$ } from '../../effector/modals';
import { YAService } from '../../services/yaService';
import AddStrategy from '../Form/AddStrategy';
import Approval from '../Form/Approval/Approval';
import { Attention } from '../Form/Attention/Attention';
import { AuthForm } from '../Form/AuthForm';
import { AutoFollowing, RemoveStrategyConfirmation } from '../Form/AutoFollowing/AutoFollowing';
import AutoFollowingAccept from '../Form/AutoFollowingAccept';
import RemoveStrategy from '../Form/RemoveStrategy/RemoveStrategy';
import { Subscribe } from '../Form/Subscribe';
import { Modal } from '../Modal/Modal';

const PopupManager: FC = () => {
  const modalsState = useStore(Modals$);

  return (
    <div>
      <Modal
        isOpen={modalsState.auth}
        onRequestClose={() => {
          YAService.sendEvent(
            YAService.eventTreeToString({
              loginForm: {
                closeForm: 'space',
              },
            }) ?? '',
          );

          modalApi.hide('');
        }}
        onCrossClose={() => {
          YAService.sendEvent(
            YAService.eventTreeToString({
              loginForm: {
                closeForm: 'cross',
              },
            }) ?? '',
          );

          modalApi.hide('');
        }}
        action={YAService.eventTreeToString({
          loginForm: 'view',
        })}
        title={messages.openAccount}
      >
        <AuthForm />
      </Modal>

      <Modal
        isOpen={modalsState.removeStrategy}
        className="_v2"
        title={messages.unbindStrategy}
        onRequestClose={() => {
          modalApi.hide('');
        }}
      >
        <RemoveStrategy />
      </Modal>

      <Modal
        isOpen={modalsState.autoFollowing}
        className="_v2"
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title={messages.bindAutofollowing}
      >
        <AutoFollowing />
      </Modal>

      <Modal
        isOpen={modalsState.autoFollowingAccept}
        className="_v2"
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title={messages.bindAutofollowing}
      >
        <AutoFollowingAccept />
      </Modal>

      <Modal
        isOpen={modalsState.removeAutoFollowing}
        className="_v2"
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title={messages.unbindStrategy}
      >
        <RemoveStrategyConfirmation />
      </Modal>

      <Modal
        isOpen={modalsState.addStrategy}
        className="_v2"
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title={messages.bindStrategy}
      >
        <AddStrategy />
      </Modal>

      <Modal
        isOpen={modalsState.recommendations}
        className="_v2"
        onRequestClose={() => {
          modalApi.hide('');
        }}
        title={messages.bindAutoconsalting}
      >
        <AutoFollowing />
      </Modal>

      <Modal
        isOpen={modalsState.attention}
        className="_v2 _no-title"
        onRequestClose={() => {
          modalApi.hide('');
        }}
      >
        <Attention />
      </Modal>

      <Modal
        isOpen={modalsState.approval}
        className="_v2 _no-title"
        onRequestClose={() => {
          modalApi.hide('');
        }}
      >
        <Approval />
      </Modal>

      <Modal
        isOpen={modalsState.subscribe}
        className="_v2 _no-title"
        onRequestClose={() => {
          modalApi.hide('');
        }}
      >
        <Subscribe />
      </Modal>
    </div>
  );
};

export default PopupManager;
