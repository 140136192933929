export enum CurrencyId {
  'HKD' = 'HKD',
  'EUR' = 'EUR',
  'USD' = 'USD',
  'RUB' = 'RUB',
  'CNY' = 'CNY',
}

export type MoneyTotal = {
  [CurrencyId.HKD]: {
    totalMoney: number;
  };
  [CurrencyId.EUR]: {
    totalMoney: number;
  };
  [CurrencyId.USD]: {
    totalMoney: number;
  };
  [CurrencyId.RUB]: {
    totalMoney: number;
  };
  [CurrencyId.CNY]: {
    totalMoney: number;
  };
};

export type MoneyFree = {
  [CurrencyId.HKD]: {
    freeMoney: number;
  };
  [CurrencyId.EUR]: {
    freeMoney: number;
  };
  [CurrencyId.USD]: {
    freeMoney: number;
  };
  [CurrencyId.RUB]: {
    freeMoney: number;
  };
  [CurrencyId.CNY]: {
    freeMoney: number;
  };
};

export type MoneyBlocked = {
  [CurrencyId.HKD]: {
    total: number;
    forGo: number;
    forOrders: number;
  };
  [CurrencyId.EUR]: {
    total: number;
    forGo: number;
    forOrders: number;
  };
  [CurrencyId.USD]: {
    total: number;
    forGo: number;
    forOrders: number;
  };
  [CurrencyId.RUB]: {
    total: number;
    forGo: number;
    forOrders: number;
  };
  [CurrencyId.CNY]: {
    total: number;
    forGo: number;
    forOrders: number;
  };
};

export type MoneyMarket = {
  [CurrencyId.HKD]: {
    marketValue: number;
  };
  [CurrencyId.EUR]: {
    marketValue: number;
  };
  [CurrencyId.USD]: {
    marketValue: number;
  };
  [CurrencyId.RUB]: {
    marketValue: number;
  };
  [CurrencyId.CNY]: {
    marketValue: number;
  };
};

export type AgreementMoney = {
  id: string;
  totalMoney: MoneyTotal;
  freeMoney: MoneyFree;
  blockedMoney: MoneyBlocked;
  marketValue: MoneyMarket;
  totalFutureLimit: MoneyTotal;
  freeFutureLimit: MoneyFree;
  blockedFutureLimit: MoneyTotal;
};

export type MoneyResponse = {
  generalAgreements: AgreementMoney[];
};
